/**
 *    USE FORM (HOOK)
 */

import _ from 'lodash';
import { useCallback, useState } from 'react';
import Utils from '_utils';

type InitState = {
  fields: any;
  validate: boolean;
  requiredText: string | Function;
  isValid: boolean;
  showValidation: boolean;
  t: any;
};

type UseFormHelperInitState = {
  fields: any;
  validate?: boolean;
  requiredText?: string | Function;
  isValid?: boolean;
  showValidation?: boolean;
  translate?: any;
};

function useForm(initialState: UseFormHelperInitState) {
  const getInitialState = (formConfig: any) => {
    let initialValid = true;
    if (formConfig.isValid === false) {
      initialValid = false;
    }
    let initState: InitState = {
      fields: {},
      validate: formConfig.validate || false,
      requiredText: formConfig.requiredText || 'required',
      isValid: initialValid,
      showValidation: formConfig.showValidation || false,
      t: formConfig.translate,
    };
    const fieldKeys = _.keys(formConfig.fields);

    fieldKeys.forEach((key) => {
      let field = formConfig.fields[key];
      field.valid = field.valid || true;
      field.msg = field.msg || '';
      field.label = field.label || key;
      field.error = false;
      initState.fields[key] = field;
    });
    return initState;
  };

  /**
   *  LOCAL STATES
   */
  const [form, setForm] = useState(getInitialState(initialState));
  const [dataHasChanged, setDataHasChanged] = useState(false);

  /**
   *  triggers the form validation
   */
  const validateForm = useCallback(
    (formObj?) => {
      let _isValid = true;
      let _form = Utils.clone(formObj || form);
      const keys = _.keys(_form.fields);

      keys.forEach((key) => {
        const field = _form.fields[key];
        field.valid = true;
        field.msg = '';
        field.error = false;

        let _fValid = true;
        let _fMsg = '';

        // validation hook
        if (typeof field.validate === 'function') {
          const result = field.validate(field.value);
          _fValid = result.valid;
          _fMsg = result.msg;
        }

        // required check
        if (
          field.required &&
          (field.value === undefined || field.value === '' || field.value === null) &&
          _fValid
        ) {
          _fValid = false;
          let requiredText = form.requiredText;
          if (typeof form.t === 'function' && typeof form.requiredText === 'function') {
            requiredText = form.requiredText(form.t);
          }
          let label = field.label;
          if (typeof field.label === 'function' && typeof form.t === 'function') {
            label = field.label(form.t);
          }
          _fMsg = `${label} ${requiredText}`;
        }

        if (!_fValid) {
          _isValid = false;

          if (_form.showValidation) {
            field.msg = _fMsg;
            field.error = true;
          }
        }
      });

      _form.isValid = _isValid;
      setForm(_form);
      return _isValid;
    },
    [form]
  );

  /**
   *  check if field exists
   */
  const fieldExists = useCallback(
    (key) => {
      if (!form.fields[key]) {
        console.error(`FORM HELPER -> no field found with key "${key}"`);
        return false;
      }
      return true;
    },
    [form]
  );

  /**
   *  get field object
   */
  const getField = useCallback(
    (key) => {
      if (fieldExists(key)) {
        return form.fields[key];
      }
      return;
    },
    [form, fieldExists]
  );

  /**
   *  change handler, update form state on change
   */
  const handleChange = useCallback(
    (event) => {
      const _key = event.target.name;
      let _value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

      if (form.fields[_key].value !== _value) {
        event.persist();
        let _form = Utils.clone(form);
        _form.fields = {
          ..._form.fields,
          [_key]: {
            ..._form.fields[_key],
            value: _value,
          },
        };
        if (form.validate) {
          validateForm(_form);
        } else {
          setForm(_form);
        }
        setDataHasChanged(true);
      }
    },
    [form, setDataHasChanged, validateForm]
  );

  /**
   *  return form data as object
   */
  const getFormData = useCallback(() => {
    let data: any = {};
    const keys = _.keys(form.fields);
    keys.forEach((key) => {
      data[key] = form.fields[key].value;
    });
    return data;
  }, [form]);

  /**
   *  returns field value
   */
  const getFieldData = useCallback(
    (key) => {
      if (fieldExists(key)) {
        return form.fields[key].value;
      }
    },
    [form, fieldExists]
  );

  /**
   *  check if field is valid
   *  return boolean
   */
  const isValidField = useCallback(
    (key) => {
      if (fieldExists(key)) {
        return form.fields[key].valid;
      }
    },
    [form, fieldExists]
  );

  /**
   *  check if field shows error state
   *  return boolean
   */
  const isFieldError = useCallback(
    (key) => {
      if (fieldExists(key)) {
        return form.fields[key].error;
      }
    },
    [form, fieldExists]
  );

  /**
   *  check if all fields passed the validation
   *  return boolean
   */
  const isValidForm = useCallback(() => {
    return form.isValid;
  }, [form]);

  /**
   *  set Field data (per default it ges set trough change-handler, this is the custom solution)
   */
  const setFieldData = useCallback(
    (key, value) => {
      if (fieldExists(key)) {
        let _form = Utils.clone(form);
        _form.fields[key].value = value;
        setDataHasChanged(true);
        if (_form.validate) {
          validateForm(_form);
        } else {
          setForm(_form);
        }
      }
    },
    [form, fieldExists, setDataHasChanged, validateForm]
  );

  /**
   *  set Field label mostly used for i18n language switch callback
   */
  const setFieldLabel = useCallback(
    (key, value) => {
      if (fieldExists(key)) {
        let _form = Utils.clone(form);
        _form.fields[key].label = value;
        setForm(_form);
      }
    },
    [form, fieldExists]
  );

  const setFieldValid = useCallback(
    (key, valid) => {
      if (fieldExists(key)) {
        let _form = Utils.clone(form);
        _form.fields[key].valid = valid;
        if (form.validate) {
          validateForm(_form);
        } else {
          setForm(_form);
        }
      }
    },
    [form, fieldExists, validateForm]
  );

  /**
   *  check if the form data has chaned since the initial state
   *  return boolean
   */
  const isFormDataChanged = useCallback(() => {
    return dataHasChanged;
  }, [dataHasChanged]);

  /**
   *  returns the form fields helper text message
   */
  const getFieldMessage = useCallback(
    (key) => {
      if (fieldExists(key)) {
        if (typeof form.fields[key].msg === 'function') {
          return form.fields[key].msg(form.t);
        } else {
          return form.fields[key].msg;
        }
      }
    },
    [fieldExists, form]
  );

  const setFieldMessage = useCallback(
    (key, message) => {
      if (fieldExists(key)) {
        let _form = Utils.clone(form);
        _form.fields[key].message = message;
      }
    },
    [fieldExists, form]
  );

  /**
   *  returns if the form validations are visible in the fields
   *  return boolean
   */
  const isShowValidation = useCallback(() => {
    return form.showValidation;
  }, [form]);

  /**
   *  sets if the form validations are visible in the fields
   */
  const setShowValidation = useCallback(
    (boolean) => {
      let _form = Utils.clone(form);
      _form.showValidation = boolean;
      if (_form.validate) {
        validateForm(_form);
      } else {
        setForm(_form);
      }
    },
    [form, setForm, validateForm]
  );

  /**
   *  returns the field label
   */
  const getFieldLabel = useCallback(
    (key) => {
      if (fieldExists(key)) {
        if (typeof form.t === 'function' && typeof form.fields[key].label === 'function') {
          return form.fields[key].label(form.t);
        }
        return form.fields[key].label;
      }
    },
    [form, fieldExists]
  );

  /**
   *  returns if field is required
   */
  const isRequiredField = useCallback(
    (key) => {
      if (fieldExists(key)) {
        return form.fields[key].required;
      }
    },
    [form, fieldExists]
  );

  /**
   *  reset the formstate to it's initial state
   */
  const resetForm = useCallback(() => {
    if (dataHasChanged) {
      const _form = getInitialState(initialState);
      if (form.validate) {
        validateForm(_form);
      } else {
        setForm(_form);
      }
      setDataHasChanged(false);
    }
  }, [form, initialState, validateForm, dataHasChanged, setDataHasChanged]);

  /**
   *  hoock api
   */
  return {
    form,
    f: {
      getField,
      handleChange,
      getFormData,
      getFieldData,
      getFieldMessage,
      setFieldMessage,
      getFieldLabel,
      setFieldLabel,
      setFieldValid,
      setFieldData,
      isValidField,
      isRequiredField,
      isFieldError,
      isValidForm,
      validateForm,
      isFormDataChanged,
      setShowValidation,
      isShowValidation,
      resetForm,
    },
  };
}

export default useForm;
