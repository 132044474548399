/**
 * LIST NAVIGATION ITEM
 */

import React, { useState, useRef } from 'react';
import { Icon } from 'components/icon/icon';
import { makeStyles, ListItem, ListItemIcon, ListItemText, Collapse, IconButton } from '@material-ui/core';
import { ListNavigationItemConfig, ListNavigationThemeConfig } from 'components/listNavigation/_types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import ListNavigation from './listNavigation';

interface ListNavigationItemProps {
  config: ListNavigationItemConfig;
  collapsed?: boolean;
  onToggleChildren?: Function;
  onSelectItem?: Function;
  theme: ListNavigationThemeConfig;
  isPopover?: boolean;
}

const levelPadding = 20;

const useStyles = makeStyles((theme) => ({
  listItemRoot: {
    '&.level-1': {
      fontSize: '0.9em',
      paddingLeft: levelPadding * 2,
      '&.borders': {
        borderBottom: '1px solid ' + theme.palette.grey[300],
      },
    },
    '&.level-2': {
      fontSize: '0.9em',
      paddingLeft: levelPadding * 3,
      '&.borders': {
        borderBottom: '1px solid ' + theme.palette.grey[300],
      },
    },
    '&.level-3': {
      paddingLeft: levelPadding * 4,
      '&.borders': {
        borderBottom: '1px solid ' + theme.palette.grey[300],
      },
    },
    '&.level-4': {
      paddingLeft: levelPadding * 5,
      '&.borders': {
        borderBottom: '1px solid ' + theme.palette.grey[300],
      },
    },
    '&.level-5': {
      paddingLeft: levelPadding * 6,
      '&.borders': {
        borderBottom: '1px solid ' + theme.palette.grey[300],
      },
    },
  },
}));

const ListNavigationItem: React.FC<ListNavigationItemProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation('content');
  const [hovered, setHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const anchorRef = useRef<any>(null);

  const handleItemClick = (event: any, item: ListNavigationItemConfig) => {
    if (event.target.closest('.toggle-btn')) {
      if (props.onToggleChildren) {
        props.onToggleChildren(item);
      }
    } else {
      if (props.onSelectItem) {
        props.onSelectItem(item);
      }
    }
  };

  /*  const handleMouseOver = (event: any) => {
    event.stopPropagation();
    setHovered(true);
    if (props.collapsed) {
      setAnchorEl(anchorRef.current);
    }
  };

  const handleMouseOut = (event: any) => {
    const x = event.clientX;
    const y = event.clientY;
    const elementMouseIsOver = document.elementFromPoint(x, y);
    event.stopPropagation();
    setHovered(false);
    if (props.collapsed || props.isPopover) {
      setAnchorEl(null);
    }
  }; */

  return (
    <>
      <ListItem
        ref={anchorRef}
        button
        // onMouseOver={handleMouseOver}
        // onMouseOut={handleMouseOut}
        onClick={(event: any) => handleItemClick(event, props.config)}
        classes={{
          root: clsx(
            'list-item',
            classes.listItemRoot,
            'transition transition-all duration-150',
            `level-${props.config.level}`,
            props.theme.listItem.all,
            props.theme.listItem.borders ? 'borders' : '',
            props.theme.listItem.borders ? ' border-solid border-b' : '',
            props.config.active || (props.config.hasActiveChild && props.collapsed)
              ? props.theme.listItem.active
              : props.theme.listItem.default,
            props.config.open && !props.collapsed && props.theme.listItem.borders
              ? 'border-gray-100'
              : ' border-gray-400'
          ),
        }}
      >
        {props.config.icon ? (
          <ListItemIcon
            //  onMouseOver={handleMouseOver}
            //  onMouseOut={handleMouseOver}
            classes={{
              root: clsx(
                'transition transition-colors duration-150',
                props.theme.listIcon.all,
                props.config.active || (props.config.hasActiveChild && props.collapsed)
                  ? props.theme.listIcon.active
                  : props.theme.listIcon.default,
                props.config.iconActiveOnly && !props.config.active ? 'opacity-0' : ''
              ),
            }}
          >
            <Icon icon={props.config.icon} />
          </ListItemIcon>
        ) : null}

        <ListItemText
          //  onMouseOver={handleMouseOver}
          //  onMouseOut={handleMouseOver}
          classes={{
            primary: clsx(
              props.theme.listItemText.all,
              'transition duration-200 text-inherit whitespace-no-wrap overflow-hidden'
              //props.collapsed ? 'opacity-0' : 'opacity-1'
            ),
          }}
          primary={props.config.translate ? t(props.config.translate) : props.config.label || ''}
        />
        {props.config.children ? (
          <IconButton
            classes={{
              root: clsx(
                'toggle-btn',
                'absolute w-32 h-32 right-0 text-14 transition-all  ease-in-out duration-150',
                props.theme.listIcon.all,
                props.config.open ? 'transform rotate-180' : 'transform rotate-0',
                props.config.active ? props.theme.listIcon.active : props.theme.listIcon.default,
                props.collapsed ? 'hidden' : ''
              ),
            }}
          >
            <Icon flex icon={['fas', 'angle-down']} />
          </IconButton>
        ) : null}
      </ListItem>
      {props.config.children ? (
        <>
          <Collapse
            in={props.config.open}
            timeout={200}
            unmountOnExit
            component="div"
            className={clsx(
              props.collapsed ? 'hidden' : '',
              props.config.level === 0 && props.config.open && props.theme.listItem.borders
                ? ' border-b border-gray-200'
                : ''
            )}
          >
            <ListNavigation
              theme={props.theme}
              config={props.config.children}
              onSelectItem={props.onSelectItem}
              onToggleChildren={props.onToggleChildren}
            />
          </Collapse>
          {/*  {anchorEl && (hovered || props.isPopover) ? (
            <>
              <ListNavigationPopover
                top={Utils.getOffset(anchorEl).top}
                left={Utils.getOffset(anchorEl).left + anchorEl.offsetWidth}
                anchorEl={anchorEl}
                anchorPos="right"
                onClose={() => setAnchorEl(null)}
                config={props.config.children}
                theme={props.theme}
                onSelectItem={props.onSelectItem}
              />
            </>
          ) : null} */}
        </>
      ) : null}
    </>
  );
};

export default ListNavigationItem;
