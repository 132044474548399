/**
 * VIEW IMAGE GALLERY
 */

import React, { FC, useEffect } from 'react';
import { makeStyles, IconButton, CircularProgress, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/_types';
import Scroll from 'components/scroll';
import clsx from 'clsx';
import { Icon } from 'components/icon/icon';
import {
  st_imageGallery_init,
  st_imageGallery_initImageUpload,
  st_imageGalelry_removeImagePersist,
} from 'store/imageGallery/imageGallerySlice';
import { getApiEndpoint } from 'api/api';
import UploadImageDialog from './insertImage/dialog/uploadImageDialog';

const useStyles = makeStyles({
  root: {
    maxWidth: 1150,
    minHeight: 300,
  },
  img: {
    height: 140,
    width: 'auto',
  },
  imgButton: {
    right: 5,
    bottom: 5,
  },
});

interface ImageGalleryProps {}

const ImageGallery: FC<ImageGalleryProps> = (props) => {
  const classes = useStyles();
  const config = useSelector((state: RootState) => state.content.config);
  const navConfig = useSelector((state: RootState) => state.content.navConfig);
  const fileIds = useSelector((state: RootState) => state.imageGallery.data?.fileIds);
  const fileIdsLength = useSelector((state: RootState) => state.imageGallery.data?.fileIds.length);
  const loading = useSelector((state: RootState) => state.imageGallery.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(st_imageGallery_init());
  }, [dispatch]);

  const handleAddImage = () => {
    dispatch(st_imageGallery_initImageUpload({}));
  };

  const handleRemoveImage = (fileId: string) => {
    dispatch(st_imageGalelry_removeImagePersist(fileId));
  };

  return (
    <>
      <Scroll className="w-full h-full pb-50">
        <div className="m-30">
          {!navConfig || !config || loading ? (
            <div className="w-full h-full absolute flex flex-col justify-center items-center">
              <CircularProgress color="secondary" size={50} />
            </div>
          ) : (
            <>
              <div className="text-32 text-secondary font-bold">{navConfig?.label}</div>
              <div className="bg-white mt-30 rounded border border-solid border-secondary overflow-hidden p-15">
                {fileIds ? (
                  <ul>
                    {fileIds.map((fileId: string) => (
                      <li className="m-10 relative inline-block">
                        <img
                          src={`${getApiEndpoint()}file/${fileId}`}
                          alt="gallery"
                          className={classes.img}
                        />
                        <IconButton
                          onClick={() => handleRemoveImage(fileId)}
                          className={clsx(
                            classes.imgButton,
                            'mr-10 w-14 h-14 absolute bg-white text-secondary'
                          )}
                        >
                          <Icon icon={['fas', 'trash']} className="text-14" />
                        </IconButton>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <CircularProgress color="secondary" className="my-20" size={50} />
                )}
              </div>
              <Button variant="contained" color="secondary" className="mt-20" onClick={handleAddImage}>
                <Icon icon={['fas', 'plus-circle']} className="mr-10 " /> Bild hinzufügen
              </Button>
            </>
          )}
        </div>
      </Scroll>
      <UploadImageDialog />
    </>
  );
};

export default ImageGallery;
