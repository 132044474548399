/**
 * HEADER NAVIGATION BUTTON
 */

import React from 'react';
import { Icon, IconConfig } from 'components/icon/icon';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import { useTheme, useMediaQuery } from '@material-ui/core';

interface HeaderNavButtonProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
  icon?: IconConfig;
  label?: string;
  labelPos?: 'left' | 'right';
  title?: string;
  onClick?: Function;
  onMouseOver?: Function;
  onMouseOut?: Function;
}

const HeaderNavButton: React.FC<HeaderNavButtonProps> = (props) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = (event: React.MouseEvent) => {
    if (props.onClick) {
      props.onClick(event);
    }
  };

  const handleMouseOver = (event: React.MouseEvent) => {
    if (props.onMouseOver) {
      props.onMouseOver(event);
    }
  };

  const handleMouseOut = (event: React.MouseEvent) => {
    if (props.onMouseOut) {
      props.onMouseOut();
    }
  };

  return (
    <button
      id={props.id}
      style={props.style}
      color="inherit"
      className={clsx(
        'text-white py-4 px-8 mt-3 rounded  hover:bg-secondary-400 flex justify-center items-center text-14',
        props.className || ''
      )}
      title={props.title}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {props.icon && props.label && props.labelPos === 'right' ? (
        <Icon icon={props.icon} className={clsx(!mobile ? 'text-18' : 'text-20', 'pr-4')} />
      ) : null}
      {props.label ? (
        <span className="inline-block p-2 whitespace-no-wrap text-14">{props.label}</span>
      ) : null}
      {props.icon && !props.label ? (
        <Icon icon={props.icon} className={clsx(!mobile ? 'text-18' : 'text-20')} />
      ) : null}
      {props.icon && props.labelPos === 'left' && props.labelPos ? (
        <Icon icon={props.icon} className={clsx(!mobile ? 'text-18' : 'text-20', 'pr-4')} />
      ) : null}
    </button>
  );
};

export default HeaderNavButton;
