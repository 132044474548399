/**
 * CK EDITOR
 */

import './ckEditorClient.css';

import React, { FC, useState } from 'react';
// @ts-ignore
import CKEditor from '@ckeditor/ckeditor5-react';
/* // @ts-ignore
import InlineEditor from '@ckeditor/ckeditor5-editor-inline/src/inlineeditor'; */
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

// @ts-ignore
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
// @ts-ignore
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
// @ts-ignore
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
// @ts-ignore
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
// @ts-ignore
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
// @ts-ignore
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
// @ts-ignore
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
// @ts-ignore
import Heading from '@ckeditor/ckeditor5-heading/src/heading';

// @ts-ignore
import Image from '@ckeditor/ckeditor5-image/src/image';
// @ts-ignore
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
// @ts-ignore
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
// @ts-ignore
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
// @ts-ignore
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
// @ts-ignore
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
// @ts-ignore
import Link from '@ckeditor/ckeditor5-link/src/link';

// @ts-ignore
import List from '@ckeditor/ckeditor5-list/src/list';
// @ts-ignore
import Table from '@ckeditor/ckeditor5-table/src/table';
// @ts-ignore
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
// @ts-ignore
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
// @ts-ignore
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
// @ts-ignore
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
// @ts-ignore
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';

import InsertImage from './plugins/insertImage/insertImage';

import InsertFile from './plugins/insertFile/insertFile';

// @ts-ignore
import CKEditorInspector from '@ckeditor/ckeditor5-inspector';

// plugins
import { useDispatch } from 'react-redux';
import { Button, CircularProgress } from '@material-ui/core';
import EditorContext from './editorContext';
import InsertImageDialog from './plugins/insertImage/dialog/insertImageDialog';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import InsertPdfDialog from './plugins/insertFile/dialog/insertFileDialog';
import { uniqueId } from 'lodash';
import Utils from '_utils';
/* import InsertImage from './plugins/insertImage/insertImage';
import InsertImageDialog from './plugins/insertImage/dialog/insertImageDialog';
import InsertLinkDialog from './plugins/insertLink/dialog/insertLinkDialog';
import { injectReducer } from '../../store/store';
import ContentEditorReducer from './_store/contentEditor.reducer'; */
/* import {
  st_content_setEditableContentEditor,
  st_content_setHasChanged,
} from '../content/_store/content.actions';
import InsertLink from './plugins/insertLink/insertLink';
import { WidgetData } from '../content/_store/content.reducer'; */

const editorConfiguration = {
  plugins: [
    Essentials,
    Paragraph,
    Bold,
    Italic,
    Underline,
    Subscript,
    Superscript,
    Image,
    ImageToolbar,
    ImageCaption,
    ImageStyle,
    ImageResize,
    Link,
    InsertImage,
    // InsertLink,
    LinkImage,
    InsertFile,
    Heading,
    List,
    Table,
    Indent,
    IndentBlock,
    TableToolbar,
    Alignment,
    MediaEmbed,
  ],
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      'underline',
      'subscript',
      'superscript',
      '|',
      'link',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'alignment',
      '|',
      'outdent',
      'indent',
      '|',
      'insertImage',
      'insertPdf',
      '|',
      'mediaEmbed',
      '|',
      'insertTable',
      '|',
      'undo',
      'redo',
    ],
    viewportTopOffset: 50,
  },

  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
  },

  link: {
    addTargetToExternalLinks: true,
  },

  image: {
    toolbar: [
      'imageTextAlternative',
      '|',
      'imageStyle:alignLeft',
      'imageStyle:full',
      'imageStyle:alignRight',
      'LinkImage',
    ],
    styles: ['full', 'alignLeft', 'alignRight'],
  },
  indentBlock: {
    offset: 1.5,
    unit: 'em',
  },
  alignment: {
    options: ['left', 'right', 'center', 'justify'],
  },
};

/* type ContentEditorProps = {
  widgetData: WidgetData;
}; */

interface CkEditorClientProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
  data: string;
  contentFieldId: string;
  inspector?: boolean;
  focusOnInit?: boolean;
  // action handlers
  onInit?: Function;
  onChange?: Function;
  onSave?: Function;
}

const CkEditorClient: FC<CkEditorClientProps> = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState('');
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editor, setEditor] = useState<any>(null);

  const editorData = (props.data as any)[props.contentFieldId];
  const handleSave = () => {
    if (props.onSave) {
      setLoading(true);
      props.onSave(data);
    }
  };

  return (
    <>
      <div id={props.id} style={props.style} className={clsx('ck-editor-wrapper', props.className || '')}>
        <CKEditor
          id={props.id}
          style={props.style}
          editor={ClassicEditor}
          config={editorConfiguration}
          data={editorData || ''}
          onInit={(editor: any) => {
            setEditor(editor);

            if (props.focusOnInit) {
              editor.editing.view.focus();
            }

            if (process.env.NODE_ENV !== 'production' && props.inspector) {
              CKEditorInspector.attach(editor);
            }

            if (props.onInit) {
              props.onInit(editor);
            }
          }}
          onChange={(event: any, editor: any) => {
            try {
              const contentData = editor.getData();
              setData(contentData);
              setChanged(true);
              if (props.onChange) {
                // props.onChange(contentData);
              }
            } catch (err) {
              console.log(err);
            }
          }}
          onBlur={(event: any, editor: any) => {}}
          onFocus={(event: any, editor: any) => {}}
        />
      </div>
      <Button
        disabled={!changed}
        color="secondary"
        variant="contained"
        className="mt-20 w-200"
        onClick={handleSave}
      >
        {loading ? <CircularProgress size={20} color="inherit" /> : 'Speichern'}
      </Button>
      {/*   <Button disabled={!changed} variant="contained" className="mt-20 ml-20" onClick={handleSave}>
        Abbrechen
      </Button> */}
      <InsertImageDialog editor={editor} />
      <InsertPdfDialog editor={editor} />
    </>
  );
};

export default CkEditorClient;
