export interface ApiContent {
  id: string;
  title: string;
  content?: string;
}

export interface ContentConfiguration {
  id: string;
  publicUri: string;
  editable: boolean;
  hasChanged?: boolean;
  containerWidth?: number;
  titles?: object;
}

const contentConfig: ContentConfiguration[] = [
  {
    id: 'website',
    publicUri: '/index.jsp',
    editable: false,
  },
  {
    id: 'website-home',
    publicUri: '/index.jsp',
    editable: false,
  },
  {
    id: 'website-actual',
    publicUri: '/aktuell.jsp',
    editable: true,
    containerWidth: 580,
    titles: {
      'website-actual-1': 'Aktuell linke Box',
      'website-actual-2': 'Aktuell rechte Box',
    },
  },
  {
    id: 'website-sustainability',
    publicUri: '/nachhaltigkeit.jsp',
    editable: true,
    titles: {
      'website-sustainability-1': 'Nachhaltigkeit Box',
    },
  },
  {
    id: 'website-products',
    publicUri: '/produkte-list.jsp',
    editable: true,
    containerWidth: 580,
    titles: {
      'website-products-1': 'Früchte, Gemüse & Kartoffelprodukte (Text)',
      'website-products-2': 'Küchenfertige Produkte (Text)',
      'website-products-3': 'Tiefkühlprodukte (Text)',
      'website-products-4': 'Milchprodukte (Text)',
    },
  },
  {
    id: 'products-fuits',
    publicUri: '/fruechte-gemuese-salate-kartoffelprodukte-list.jsp',
    editable: true,
    containerWidth: 580,
    titles: {
      'products-fuits-1': 'Früchte (Liste)',
      'products-fuits-2': 'Gemüse (Liste)',
      'products-fuits-3': 'Salate (Liste)',
      'products-fuits-4': 'Kartoffeln (Liste)',
      'products-fuits-5': 'Diverses (Liste)',
      'products-fuits-6': 'Sortimentlisten (Liste, Donwloads)',
    },
  },
  {
    id: 'products-ready-to-cook',
    publicUri: '/kuechenfertige-produkte-list.jsp',
    editable: true,
    containerWidth: 350,
    titles: {
      'products-ready-to-cook-1': 'Produkte (Liste)',
      'products-ready-to-cook-2': 'Sortimentslisten (Liste, Downloads)',
      'products-ready-to-cook-3': 'Produzenten (Liste)',
    },
  },
  {
    id: 'products-frozen',
    publicUri: '/kuechenfertige-produkte-list.jsp',
    editable: true,
    containerWidth: 580,
    titles: {
      'products-frozen-1': 'Übersicht (Liste)',
      'products-frozen-2': 'Sortimentslisten (Liste, Downloads)',
    },
  },
  {
    id: 'products-milk',
    publicUri: '/milchprodukte.jsp',
    editable: true,
    containerWidth: 580,
    titles: {
      'products-milk-1': 'Übersicht (Liste)',
      'products-milk-2': 'Sortimentslisten (Liste, Downloads)',
    },
  },
  {
    id: 'website-about',
    publicUri: '/ueber_uns_start.jsp',
    editable: false,
  },
  {
    id: 'about-sgg-waser-ag',
    publicUri: '/ueber_uns.jsp',
    editable: true,
    containerWidth: 420,
    titles: {
      'about-sgg-waser-ag-1': 'Über uns (Text)',
    },
  },
  {
    id: 'about-simmen-gemuese-en-gros-ag',
    publicUri: '/simmen-gemuese-en-gros-ag.jsp',
    editable: true,
    titles: {
      'about-simmen-gemuese-en-gros-ag-1': 'Simmen Gemüse en gros AG (Text)',
    },
  },
  {
    id: 'website-jobs',
    publicUri: '/jobs.jsp',
    editable: true,
    titles: {
      'website-jobs-1': 'Jobs (Box)',
    },
  },
  {
    id: 'website-contact',
    publicUri: '/kontakt.jsp',
    editable: false,
  },
  {
    id: 'contact-zurich',
    publicUri: '/standort_zh.jsp',
    editable: true,
    containerWidth: 750,
    titles: {
      'contact-zurich-1': 'Öffnungszeiten (Box)',
      'contact-zurich-2': 'Standort Zürich (Box)',
    },
  },
  {
    id: 'contact-schlieren',
    publicUri: '/standort_schlieren.jsp',
    editable: true,
    containerWidth: 750,
    titles: {
      'contact-schlieren-1': 'Öffnungszeiten (Box)',
      'contact-schlieren-2': 'Standort Schlieren (Box)',
    },
  },
  {
    id: 'contact-simmen-gemuese-en-gros-ag',
    publicUri: '/standort_simmen.jsp',
    editable: false,
  },
  {
    id: 'website-links',
    publicUri: '/links.jsp',
    editable: true,
    titles: {
      'website-links-1': 'Links (Box)',
    },
  },
  {
    id: 'website-shop',
    publicUri: '/shop.jsp',
    editable: true,
    titles: {
      'website-shop-1': 'Shop (Box)',
    },
  },
  {
    id: 'website-image-gallery',
    publicUri: '/shop.jsp',
    editable: false,
  },
];

export default contentConfig;
