/**
 *    UTILS
 */

class Utils {
  static uuid(): string {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  static clone(src: object): any {
    return Object.assign({}, src);
  }

  static async_timeout(duration: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, duration));
  }

  static getPrettyFileSize(bytes: number, si?: boolean): string {
    si = si || true;
    let thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
    let units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
  }

  static cloneArray = (arr: any[]) => {
    let list: any = [];
    arr.forEach((item) => list.push(Utils.clone(item)));
    return list;
  };

  static reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  static linbreaksToBr(string: string) {
    if (string) {
      return string.replace(/\n/g, '<br />');
    } else {
      return null;
    }
  }

  static brToLinebreaks(string: string) {
    return string.replace(/<br \/>/g, '\n');
  }

  static getFragment(index?: number) {
    /*  let path = browserHistory.location.pathname.replace('/', '');

    if (index === undefined) {
      return path;
    }
    const parts = path.split('/');
    return parts[index]; */
  }

  static validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static validateUri(str: string) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  }

  static randomInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  static slugify(string: string) {
    return string
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/ä/g, 'ae')
      .replace(/ö/g, 'oe')
      .replace(/ü/g, 'ue')
      .replace(/[ÉéÈè]/g, 'e')
      .replace(/ÛûÙù/g, 'u')
      .replace(/Ôô/g, '')
      .replace(/[ÀàÁáÂâ]/g, 'a')
      .replace(/[^\w-]+/g, '')
      .replace(/--+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  }

  static addStyleToHead(styleString: string, id?: string) {
    var ss = document.createElement('style');
    ss.innerText = styleString;
    if (id) {
      ss.setAttribute('id', id);
    }
    document.getElementsByTagName('head')[0].appendChild(ss);
  }

  static getOffset(el: HTMLElement) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };
  }
}

export default Utils;
