/**
 * MAIN NAVIGATION REDUCER
 */

import { createSlice } from '@reduxjs/toolkit';

interface UserNavigationState {
  open: boolean;
}

const initialState: UserNavigationState = {
  open: false,
};

const userNavigationSlice = createSlice({
  name: 'userNavigation',
  initialState: initialState,
  reducers: {
    st_userNavigation_setOpen: (state, action) => {
      state.open = action.payload;
    },
  },
});

export const { st_userNavigation_setOpen } = userNavigationSlice.actions;

export default userNavigationSlice.reducer;
