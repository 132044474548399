/**
 * DYNAMIC ROUTER
 */

import React, { useCallback, Fragment, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteConfig } from './_types';
import { useSelector } from 'react-redux';
import { RootState } from 'store/_types';
import { appLogger } from 'components/app/app';
import Utils from '_utils';

interface DynamicRouterProps {
  routes: RouteConfig[];
}

const DynamicRouter: React.FC<DynamicRouterProps> = (props) => {
  const auth = useSelector((state: RootState) => state.auth.user?.roles);

  const getRoute = (route: RouteConfig) => {
    let component: React.FC = () => {
      return null;
    };
    let isAuthorized = true;

    // check authentication
    if (route.auth && auth) {
      isAuthorized = false;
      route.auth.forEach((at) => {
        if (auth.indexOf(at) !== -1) {
          isAuthorized = true;
        }
      });
    }

    // not authorized
    if (!isAuthorized) {
      appLogger.log(`unauthorized route request: "${route.path}"`, [route], 'collapsed');
      if (route.authFallbackComponent) {
        component = route.authFallbackComponent;
      }
    }
    // authorized
    else {
      component = route.component;
    }
    return <Route key={Utils.uuid()} path={route.path} exact={route.exact} component={component} />;
  };

  return <>{props.routes.map((route) => getRoute(route))}</>;
};

export default DynamicRouter;
