/**
 * LAYOUT HEADER
 */

import React from 'react';
import { AppBar } from '@material-ui/core';

interface LayoutFooterProps {
  content: React.ReactNode;
}

const LayoutFooter: React.FC<LayoutFooterProps> = (props) => {
  return (
    <AppBar
      color="inherit"
      position="absolute"
      classes={{
        root: 'relative h-full w-full bg-secondary',
      }}
      elevation={3}
    >
      {props.content}
    </AppBar>
  );
};

export default LayoutFooter;
