import React from 'react';
import logoText from '_assets/logo_text.png';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import HeaderSearch from './search/headerSearch';
import LanguageNavigation from './languageNavigation/languageNav';
import HeaderUserNav from './userNavigation/userNavigation';

const useStyles = makeStyles((theme) => ({
  logo: {
    [theme.breakpoints.up('md')]: {
      width: 260,
    },
  },
}));

const Header: React.FC = () => {
  const classes = useStyles();

  return (
    <div className="h-full w-full flex flex-1 flex-row relative text-white">
      <div className="header-left h-full p-6 flex-none">
        <div id="logo" className={clsx('h-full')}>
          <Link to="/" id="goto-home" className="inline-block h-full">
            <img className="h-full w-auto" src={logoText} alt="logo" />
          </Link>
        </div>
      </div>
      <div className="header-center h-full p-6 w-full"></div>
      <div className="header-right h-full p-6 flex flex-1">
        {/*  <HeaderSearch className="mt-4 mr-16" /> */}
        <LanguageNavigation className="mr-16 w-40" />
        <HeaderUserNav />
      </div>
    </div>
  );
};

export default Header;
