/**
 * AUTH REDUCER
 */

import Keycloak from 'keycloak-js';
import KeycloakClient from '@phoenix-systems/keycloak-client';
import kcConfig from '_config/keycloak.json';
import { createSlice, Dispatch } from '@reduxjs/toolkit';
import api from 'api';

interface AuthUser {
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  roles: string[];
}

interface AuthState {
  embedded: boolean;
  loggedIn: boolean;
  client: any;
  user: AuthUser | null;
}

const initialState: AuthState = {
  embedded: false,
  loggedIn: false,
  client: null,
  user: null,
};

// redhat client
let keycloakJs: any;

// embedded client
let keycloak: KeycloakClient;

const getUserDataFromToken = (token: any) => {
  if (!token) {
    return null;
  }
  return {
    firstName: token.given_name,
    lastName: token.family_name,
    name: token.name,
    roles: token.groups,
    email: token.email,
  };
};

export const getKeycloakClient = () => {
  if (keycloakJs) {
    return keycloakJs;
  }
  if (keycloak) {
    return keycloak;
  }
};

export const getTokenFromJsClient = () => {
  return keycloakJs.idToken;
};

export const st_auth_init = (embedded?: boolean) => {
  return async (dispatch: Dispatch) => {
    // redhat keycloak-js adapter
    if (!embedded) {
      keycloakJs = Keycloak('/keycloak.json');
      keycloakJs
        .init({ onLoad: 'login-required' })
        .then((authenticated: boolean) => {
          window.history.pushState('', document.title, window.location.pathname + window.location.search);
          dispatch(st_auth_setloggedIn(authenticated));
          const token = keycloakJs.idTokenParsed;
          dispatch(st_auth_setUser(getUserDataFromToken(token)));
          api.initAuthorization(getTokenFromJsClient, getKeycloakClient());
          return true;
        })
        .catch((err: any) => {
          console.log(err);
          return false;
        });
    }

    // embedded adapter
    else {
      keycloak = new KeycloakClient({ parsedKcConfig: kcConfig });
      dispatch(st_auth_setEmbedded(true));
      try {
        await keycloak.init();
        if (keycloak.isAuthTokenValid()) {
          const token = keycloak.getParsedToken();
          dispatch(st_auth_setloggedIn(true));
          dispatch(st_auth_setUser(getUserDataFromToken(token)));
          await keycloak.getValidToken();
          api.initAuthorization(keycloak.getValidToken, keycloak);
          return true;
        }
      } catch (err) {
        dispatch(st_auth_setloggedIn(false));
        dispatch(st_auth_setUser(getUserDataFromToken(null)));
        return false;
      }
    }
  };
};

function getTokenHandler() {
  return keycloakJs.accessToken;
}

export const st_auth_logout = () => {
  return async (dispatch: Dispatch, getState: any) => {
    if (!getState().auth.embedded) {
      keycloakJs
        .logout()
        .then((authenticated: boolean) => {
          dispatch(st_auth_setloggedIn(false));
          dispatch(st_auth_setUser(null));

          api.initAuthorization(getTokenHandler, keycloakJs);
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else {
      try {
        await keycloak.logout();
        dispatch(st_auth_setloggedIn(false));
        dispatch(st_auth_setUser(null));
      } catch (err) {}
    }
  };
};

export const st_auth_login = (userName: string, password: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      await keycloak.authenticate(userName, password);
      const token = keycloak.getParsedToken();
      dispatch(st_auth_setUser(getUserDataFromToken(token)));
      dispatch(st_auth_setloggedIn(true));
    } catch (err) {
      dispatch(st_auth_setUser(null));
      dispatch(st_auth_setloggedIn(false));
      console.log(err);
    }
  };
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    st_auth_setEmbedded: (state, action) => {
      state.embedded = action.payload;
      return state;
    },
    st_auth_setloggedIn: (state, action) => {
      state.loggedIn = action.payload;
      return state;
    },
    st_auth_setUser: (state, action) => {
      state.user = action.payload;
      return state;
    },
  },
});

export const { st_auth_setEmbedded, st_auth_setloggedIn, st_auth_setUser } = authSlice.actions;

export default authSlice.reducer;
