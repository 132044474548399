/**
 * HEADER DROPDOWN NAVIGATION
 */

import React from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Popover } from '@material-ui/core';

interface HeaderDropdownNavProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
  content: React.ReactNode;
  anchorPos: 'left' | 'right' | 'center';
  anchorEl: HTMLElement | null;
  onClose: Function;
}

const HeaderDropdownNav: React.FC<HeaderDropdownNavProps> = (props) => {
  const open = Boolean(props.anchorEl);

  return (
    <div id={props.id} className={props.className} style={props.style}>
      <Popover
        id="mouse-over-popover"
        className="mt-4"
        open={open}
        anchorEl={props.anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: props.anchorPos,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: props.anchorPos,
        }}
        onClose={() => props.onClose()}
        disableRestoreFocus
        marginThreshold={0}
        elevation={3}
      >
        <div>{props.content}</div>
      </Popover>
    </div>
  );
};

export default HeaderDropdownNav;
