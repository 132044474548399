/**
 * LANGUAGE REDUCER
 */

import { createSlice } from '@reduxjs/toolkit';
import { LanguagItemConfig, LanguageId } from 'i18n/_types';
import languageConfig from 'i18n/_config';
import i18next from 'i18next';

interface LanguageState {
  languages: LanguagItemConfig[];
  currentLanguage: LanguagItemConfig;
}

const defaultLanguage = languageConfig.languages.filter(
  (lang) => lang.id === languageConfig.defaultLanguageId
)[0];

const initialState: LanguageState = {
  languages: languageConfig.languages,
  currentLanguage: defaultLanguage,
};

const languageSlice = createSlice({
  name: 'language',
  initialState: initialState,
  reducers: {
    st_language_setLanguage: (state, action) => {
      const lang = state.languages.filter(
        (lang) => lang.id === (action.payload as LanguageId)
      )[0];
      if (lang) {
        i18next.changeLanguage(action.payload, () => {
          state.currentLanguage = lang;
        });
      }
    },
  },
});

export const { st_language_setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
