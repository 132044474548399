/**
 * LAYOUT HEADER
 */

import React from 'react';
import { useTheme, useMediaQuery, IconButton, AppBar } from '@material-ui/core';
import { Icon } from 'components/icon/icon';
import layoutConfig from './_config';

interface LayoutHeaderProps {
  content: React.ReactNode;
  handleToggleSidebar: Function;
}

const LayoutHeader: React.FC<LayoutHeaderProps> = (props) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <AppBar
      position="absolute"
      color="inherit"
      elevation={3}
      classes={{
        root: 'h-full z-8000 flex flex-1 flex-row text-white bg-secondary',
      }}
    >
      {mobile ? (
        <>
          {!layoutConfig.sidebarLeft.disabled ? (
            <IconButton
              color="inherit"
              onClick={() => props.handleToggleSidebar('left')}
              className="focus:outline-none flex-initial"
            >
              <Icon icon={['fas', 'bars']} className="text-white text-24" ariaLabel="open main navigation" />
            </IconButton>
          ) : null}
          <div className="flex-shrink w-full">{props.content}</div>
          {!layoutConfig.sidebarRight.disabled ? (
            <IconButton
              onClick={() => props.handleToggleSidebar('right')}
              className="focus:outline-none flex-initial"
            >
              <Icon icon={['fas', 'bars']} className="text-white" ariaLabel="open right side navigation" />
            </IconButton>
          ) : null}
        </>
      ) : (
        <>{props.content}</>
      )}
    </AppBar>
  );
};

export default LayoutHeader;
