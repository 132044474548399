/**
 * APP REDUCER
 */

import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { st_language_setLanguage } from 'store/language/languageSlice';
import languageConfig from 'i18n/_config';
import { st_layout_setSidebarLeftOpen, st_layout_setSidebarRightOpen } from 'store/layout/layoutSlice';

interface AppState {
  initialized: boolean;
}

const initialState: AppState = {
  initialized: false,
};

export const st_app_init = (mobile: boolean) => {
  return (dispatch: Dispatch) => {
    window.history.pushState('', document.title, window.location.pathname + window.location.search);
    dispatch(st_language_setLanguage(languageConfig.defaultLanguageId));
    dispatch(st_app_setInititalized(true));
    if (mobile) {
      dispatch(st_layout_setSidebarLeftOpen(false));
      dispatch(st_layout_setSidebarRightOpen(false));
    } else {
      dispatch(st_layout_setSidebarLeftOpen(true));
      dispatch(st_layout_setSidebarRightOpen(false));
    }
  };
};

const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    st_app_setInititalized: (state, action) => {
      state.initialized = action.payload;
      return state;
    },
  },
});

export const { st_app_setInititalized } = appSlice.actions;

export default appSlice.reducer;
