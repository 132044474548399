/**
 * APP
 */
import React, { useEffect } from 'react';
import Layout from 'components/layout';
import { useDispatch, useSelector } from 'react-redux';
import { st_app_init } from 'store/app/appSlice';
import Header from 'components/header';
import { RootState } from 'store/_types';
import appTheme from '_config/muiTheme';
import { ThemeProvider, useMediaQuery, useTheme } from '@material-ui/core';
import SidebarLeft from 'components/sidebarLeft/sidebarLeft';
import { StylesProvider } from '@material-ui/core/styles';
import Auth from 'components/auth';
import ConsoleClient from '@phoenix-systems/console-client';
import { env, appConfig } from '_config';
import Footer from 'components/footer';
import Views from 'views';
import { Router } from 'react-router-dom';
import browserHistory from 'browserHistory';
import SplashScreen from 'components/splashScreen';

// init console client (@phoenix-systems/console-client)
export const appLogger = new ConsoleClient({
  prefix: 'SGG CMS',
  enabled: env === 'development' ? true : false,
});
appLogger.log('init', [appConfig], 'collapsed');

const App = () => {
  const dispatch = useDispatch();
  const initialized = useSelector((state: RootState) => state.app.initialized);
  const loggedIn = useSelector((state: RootState) => state.auth.loggedIn);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (loggedIn) {
      dispatch(st_app_init(mobile));
    }
  }, [dispatch, loggedIn, mobile]);

  return (
    <ThemeProvider theme={appTheme}>
      {/* @ts-ignore */}
      <StylesProvider injectFirst>
        <Auth>
          {initialized ? (
            <Router history={browserHistory}>
              <Layout
                sidebarLeft={<SidebarLeft />}
                sidebarRight={<div>right</div>}
                main={<Views />}
                header={<Header />}
                footer={<Footer />}
              />
            </Router>
          ) : (
            <SplashScreen />
          )}
        </Auth>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
