/**
 * CONTENT EDITOR CONFIG
 */
import { ViewConfig } from 'views/_types';
import ContentEditor from '../contentEditor';
import ImageGallery from '../imageGallery';

const contentEditorConfig: ViewConfig = {
  routes: [
    {
      path: '/website/image-gallery',
      exact: true,
      component: ImageGallery,
    },
    {
      path: '/website',
      component: ContentEditor,
    },
  ],
};

export default contentEditorConfig;
