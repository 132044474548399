/**
 * LAYOUT SIDEBAR
 */

import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import layoutConfig from './_config';
import { Icon } from 'components/icon/icon';

const useStyles = makeStyles((theme) => ({
  drawer: {
    '&.left': {
      width: layoutConfig.sidebarLeft.expanded,
    },
    '&.right': {
      width: layoutConfig.sidebarRight.expanded,
    },
  },
  toggleButton: {
    top: 10,
    '&.left': {
      right: 10,
    },
    '&.right': {
      left: 10,
    },
  },
}));

interface MobileLayoutSidebarProps {
  type: 'left' | 'right';
  content: React.ReactNode;
  open: boolean;
  handleToggleOpen: Function;
  width: number;
}

const MobileLayoutSidebar: React.FC<MobileLayoutSidebarProps> = (props) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor={props.type}
      open={props.open || false}
      onClose={() => props.handleToggleOpen()}
      classes={{ paper: clsx(props.type, classes.drawer, 'z-9999 flex flex-1 flex-col') }}
    >
      <IconButton
        color="secondary"
        onClick={() => props.handleToggleOpen()}
        classes={{
          root: clsx(
            props.type,
            classes.toggleButton,
            'w-36 h-36 p-2 absolute z-9999 bg-secondary text-white absolute rounded-full shadow  hover:bg-secondary hover:text-white transition-transform',
            props.open ? 'transform rotate-180' : 'transform rotate-0'
          ),
        }}
      >
        <Icon className="text-16" icon={['fas', props.type === 'left' ? 'chevron-right' : 'chevron-left']} />
      </IconButton>
      <div className="overflow-hidden h-full relative"> {props.content} </div>
    </Drawer>
  );
};

export default MobileLayoutSidebar;
