/**
 * APP REDUCER
 */

import { createSlice } from '@reduxjs/toolkit';

export type ImageUploadDialogType = 'UPLOAD_DECISION' | 'BROWSE_IMAGE_LOCAL' | 'UPLOAD_IMAGE';
export type FileUploadDialogType = 'UPLOAD_DECISION' | 'BROWSE_FILE_LOCAL' | 'UPLOAD_FILE';

interface ckeditorState {
  imageUpload: {
    open?: string;
    dialogType: ImageUploadDialogType;
  };
  fileUpload: {
    open?: string;
    dialogType: FileUploadDialogType;
  };
}

const initialState: ckeditorState = {
  imageUpload: {
    open: undefined,
    dialogType: 'UPLOAD_DECISION',
  },
  fileUpload: {
    open: undefined,
    dialogType: 'UPLOAD_DECISION',
  },
};

const ckeditorSlice = createSlice({
  name: 'ckeditor',
  initialState: initialState,
  reducers: {
    st_ckeditor_initImageUpload: (state, action) => {
      state.imageUpload.open = action.payload;
      state.imageUpload.dialogType = 'UPLOAD_DECISION';
      return state;
    },
    st_ckeditor_setImageUploadDialogType: (state, action) => {
      state.imageUpload.dialogType = action.payload;
      return state;
    },
    st_ckeditor_imageUploadClose: (state, action) => {
      state.imageUpload.open = undefined;
      return state;
    },
    st_ckeditor_initFileUpload: (state, action) => {
      state.fileUpload.open = action.payload;
      state.fileUpload.dialogType = 'UPLOAD_DECISION';
      return state;
    },
    st_ckeditor_setFileUploadDialogType: (state, action) => {
      state.fileUpload.dialogType = action.payload;
      return state;
    },
    st_ckeditor_fileUploadClose: (state, action) => {
      state.fileUpload.open = undefined;
      return state;
    },
  },
});

export const {
  st_ckeditor_initImageUpload,
  st_ckeditor_setImageUploadDialogType,
  st_ckeditor_imageUploadClose,
  st_ckeditor_initFileUpload,
  st_ckeditor_setFileUploadDialogType,
  st_ckeditor_fileUploadClose,
} = ckeditorSlice.actions;

export default ckeditorSlice.reducer;
