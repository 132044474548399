/**
 *    CONTENT LINK DIALOG
 */
import React, { useEffect, useState } from 'react';
import { Dialog } from '@material-ui/core';
import { useSelector } from 'react-redux';
import UploadDecision from './uploadDecisiton';
import BrowseLocal from './browseLocal';
import { RootState } from 'store/_types';
import Utils from '_utils';
import { getApiEndpoint } from 'api/api';
import { getTokenFromJsClient } from 'store/auth/authSlice';
import FileUpload from '@phoenix-systems/file-upload';

interface InsertPdfDialogProps {
  editor: any;
}

const InsertFileDialog: React.FC<InsertPdfDialogProps> = (props) => {
  const open = useSelector((state: RootState) => state.ckeditor.fileUpload.open);
  const type = useSelector((state: RootState) => state.ckeditor.fileUpload.dialogType);
  const [uploadService, setUploadService] = useState<FileUpload | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (props.editor && open !== undefined && open === props.editor.id) {
      const upload = new FileUpload({
        id: Utils.uuid(),
        api: {
          endpoint: `${getApiEndpoint()}file`,
          headers: {
            Authorization: `Bearer ${getTokenFromJsClient()}`,
          },
          formDataFieldName: 'file[]',
        },
        //  logger: true,
      });
      setDialogOpen(true);
      setUploadService(upload);
    } else {
      setDialogOpen(false);
    }
  }, [open, props.editor]);

  const getDialogSize = () => {
    switch (type) {
      case 'UPLOAD_DECISION':
        return 'sm';
      case 'BROWSE_FILE_LOCAL':
        return 'md';
      case 'UPLOAD_FILE':
        return 'lg';
      default:
        return 'sm';
    }
  };

  const getDialogContent = () => {
    switch (type) {
      case 'UPLOAD_DECISION':
        return <UploadDecision />;
      case 'BROWSE_FILE_LOCAL':
        return <BrowseLocal editor={props.editor} uploadService={uploadService} />;
      default:
        return null;
    }
  };

  return (
    <Dialog open={dialogOpen} maxWidth={getDialogSize()} fullWidth>
      <div className="dialog insert-image-dialog m-20">{getDialogContent()}</div>
    </Dialog>
  );
};

export default InsertFileDialog;
