/**
 *    BROWSE LOCAL
 */
import './insertFileDialog.css';
import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { getApiEndpoint } from 'api/api';
import addFile from '../add-file.svg';
import { st_ckeditor_imageUploadClose, st_ckeditor_fileUploadClose } from 'store/ckeditor/ckeditorSlice';
import FileUpload from '@phoenix-systems/file-upload';
import Utils from '_utils';

interface BrowseLocalProps {
  editor: any;
  uploadService: FileUpload | null;
}

const BrowseLocal: React.FC<BrowseLocalProps> = (props) => {
  const { t } = useTranslation('content');
  const browseContainer = useRef(null);
  const dispatch = useDispatch();
  const [file, setFile] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [displayName, setDisplayName] = useState('');

  useEffect(() => {
    if (browseContainer.current && props.editor && props.uploadService) {
      props.uploadService.initDragDrop(browseContainer.current);
      props.uploadService.uppy.on('file-added', (file: any) => {
        //setLoading(true);
      });

      props.uploadService.onBeforeFileAdded = (file: any) => {
        const absolutePath = URL.createObjectURL(file.data);

        setFile({
          uploaded: false,
          absolutePath: absolutePath,
          name: file.name,
          mimeType: file.type,
          size: file.data.size,
        });
      };

      props.uploadService.onUploadProgress = (file: any, progress: any) => {
        console.log(progress);
      };

      props.uploadService.onUploadSucces = (file: any, response: any) => {
        if (response.status === 200) {
          setLoading(false);
          const fileUrl = `${getApiEndpoint()}file/${response.body[0].fileId}`;
          props.editor.execute('link', fileUrl, { linkOpenInNewTab: true, label: displayName });

          // props.editor.execute('link', fileUrl, { linkOpenInNewTab: true });
          dispatch(st_ckeditor_fileUploadClose(null));

          /*   props.editor.model.change((writer: any) => {
            const linkElement = writer.createElement('link', {
              label: displayName,
              src: fileUrl,
              openInNewTab: true,
            });
            // Insert the image in the current selection location.
            props.editor.model.insertContent(linkElement, props.editor.model.document.selection);
          }); */
        }
      };
    }
  }, [browseContainer, props.uploadService, props.editor, dispatch, displayName]);

  const handleUpload = () => {
    if (props.uploadService) {
      setLoading(true);
      props.uploadService.upload();
    }
  };

  const handleSetDisplayname = (event: any) => {
    const val = event.target.value;
    setDisplayName(val);
  };

  return (
    <div className="browse-image">
      <header className="header">
        <h2 className="text-32 font-bold  mb-20">{t('BROWSE_FILE')}</h2>
      </header>
      <div className="dialog-content">
        {!file ? (
          <div className="dialog-content-main bg-gray-200">
            <div ref={browseContainer} className="browse-container"></div>
          </div>
        ) : (
          <>
            <div className="image-container relative">
              <img src={addFile} alt="upload" className="w-100 h-auto" />
            </div>

            <p className="ml-4 text-12">Original Name: {file.name}</p>
            <p className="mb-20 ml-4 text-12">Dateigrösse: {Utils.getPrettyFileSize(file.size)}</p>
            {/*  <TextField
              label="Anzeigename"
              defaultValue={file.name}
              variant="outlined"
              name="displayName"
              fullWidth
              onChange={handleSetDisplayname}
              className="mb-20"
            /> */}
          </>
        )}

        <div className="buttons">
          <Button
            variant="contained"
            onClick={() => dispatch(st_ckeditor_fileUploadClose(null))}
            className="ml-20"
          >
            {t('CANCEL')}
          </Button>
          {file ? (
            <Button variant="contained" color="secondary" onClick={handleUpload} className="ml-20">
              {t('UPLOAD_FILE')}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default BrowseLocal;
