import { ViewConfig, RouteConfig } from '../_types';

export const generateRoutesFromConfigs = (configs: ViewConfig[]) => {
  let routes: RouteConfig[] = [];
  configs.forEach((config) => {
    config.routes.forEach((route) => {
      routes.push(route);
    });
  });
  return routes;
};
