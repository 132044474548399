/**
 * VIEWS
 */

import React, { useEffect } from 'react';
import browserHistory from 'browserHistory';
import { getFlatConfig } from 'components/listNavigation/_utils';
import { st_mainNavigation_setActive } from 'store/navigations/mainNavigationSlice';
import { useDispatch } from 'react-redux';
import DynamicRouter from 'components/dynamicRouter';
import pageRoutes from './_config/routes';
import mainNavigationConfig from './_config/mainNavigation';

interface ViewsProps {}

const Views: React.FC<ViewsProps> = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    browserHistory.listen(() => {
      const path = browserHistory.location.pathname;

      const configs = getFlatConfig(mainNavigationConfig, []);
      configs.forEach((config) => {
        if (path === config.path) {
          dispatch(st_mainNavigation_setActive(config.id));
        }
      });
    });
  }, [dispatch]);

  return (
    <div className="absolute h-full w-full">
      <DynamicRouter routes={pageRoutes} />
    </div>
  );
};

export default Views;
