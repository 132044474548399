import { combineReducers } from '@reduxjs/toolkit';
import mainNavigation from './mainNavigationSlice';
import userNavigation from './userNavigationSlice';
import languageNavigation from './languageNavigationSlice';

export const navigationReducer = combineReducers({
  mainNavigation,
  userNavigation,
  languageNavigation,
});

export default navigationReducer;
