/**
 * LIST NAVIGATION UTILS
 */

import { ListNavigationConfig, ListNavigationItemConfig } from '../_types';
import browserHistory from 'browserHistory';

export const initConfig = (
  nav: ListNavigationConfig,
  level: number,
  idPath: string[],
  parentId?: string | null
) => {
  const browserPath = browserHistory.location.pathname;
  nav.level = level;
  nav.parentId = parentId || null;
  nav.idPath = idPath;

  nav.items.forEach((item) => {
    let path: string[] = [];
    idPath.forEach((item) => {
      path.push(item);
    });
    path.push(item.id);
    item.level = level;
    item.parentId = parentId;
    item.idPath = path;
    if (item.path === browserPath) {
      item.active = true;
    }
    if (item.children) {
      initConfig(item.children, level + 1, path, item.id);
    }
  });
  return nav;
};

export const getFlatConfig = (nav: ListNavigationConfig, items: ListNavigationItemConfig[]) => {
  nav.items.forEach((item) => {
    items.push(item);
    if (item.children) {
      getFlatConfig(item.children, items);
    }
  });
  return items;
};

export const findItemByPath = (nav: ListNavigationConfig) => {
  const path = browserHistory.location.pathname;
  const navItems = getFlatConfig(nav, []);
  return navItems.filter((item) => item.path === path)[0];
};

export const getFlatConfigNav = (nav: ListNavigationConfig, navigations: ListNavigationConfig[]) => {
  navigations.push(nav);
  nav.items.forEach((item) => {
    if (item.children) {
      getFlatConfigNav(item.children, navigations);
    }
  });
  return navigations;
};

export const findNavItem = (nav: ListNavigationConfig, navId: string) => {
  const flatConf = getFlatConfig(nav, []);
  return flatConf.filter((nav) => nav.id === navId)[0];
};

export const findNav = (nav: ListNavigationConfig, navId: string) => {
  const flatConf = getFlatConfigNav(nav, []);
  return flatConf.filter((nav) => nav.id === navId)[0];
};

export const setOpenSelectedPath = (nav: ListNavigationConfig, itemId: string) => {
  const item = findNavItem(nav, itemId);
  if (item && item.parentId) {
    const parentNav = findNav(nav, item.parentId);
    if (parentNav) {
      setOpenSelectedPath(parentNav, itemId);
    }
  }
};
