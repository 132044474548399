import RestClient from '@phoenix-systems/rest-client';
import { env } from '_config';

// api configuration
export const apiHost = {
  dev: 'https://sgg-api.prod.phoenix-systems.ch/api/',
  test: 'https://sgg-api.prod.phoenix-systems.ch/api/',
  prod: 'https://sgg-api.prod.phoenix-systems.ch/api/',
};

export const getApiEndpoint = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return apiHost.dev;
    case 'test':
      return apiHost.test;
    case 'production':
      return apiHost.prod;
    default:
      return;
  }
};

// init rest client (@phoenix-systems/rest-client)
const api = new RestClient({
  baseUrl: apiHost,
  logger: env === 'development' ? true : false,
});

export default api;
