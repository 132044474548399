/**
 * VIEW - ERROR PAGE
 */

import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

interface ErrorPageProps {}

const ErrorPage: FC<ErrorPageProps> = (props) => {
  return (
    <div className="flex flex-1 flex-col h-full w-full justify-center">
      <div className="inline-block">
        <Switch>
          <Route path="/404">
            <h2 className="text-32 text-secondary font-bold text-center ">404 - Seite nicht gefunden</h2>
            <p className="text-16 mt-10 text-center">Die von Ihnen aufgerufene Seite wurde nicht gefunden.</p>
          </Route>
          <Route path="/503">
            <h2 className="text-32 text-secondary font-bold text-center">503 - Server Error</h2>
            <p className="text-16 mt-10 text-center">
              Interner Server Fehler. Bitte wenden Sie sich an den Administator oder versuchen Sie es erneut.
            </p>
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default ErrorPage;
