/**
 * LAYOUT MAIN
 */

import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { st_layout_setSidebarLeftOpen, st_layout_setSidebarRightOpen } from 'store/layout/layoutSlice';
import { useMediaQuery, useTheme } from '@material-ui/core';
import LayoutSidebar from './layoutSidebar';
import MobileLayoutSidebar from './mobileLayoutSidebar';
import LayoutHeader from './layoutHeader';
import LayoutFooter from './layoutFooter';
import layoutConfig from './_config';
import { RootState } from 'store/_types';

interface LayoutProps {
  main: React.ReactNode;
  sidebarLeft: React.ReactNode;
  sidebarRight: React.ReactNode;
  header: React.ReactNode;
  footer: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  headerRoot: {
    height: layoutConfig.header.height,
  },
  footerRoot: {
    height: layoutConfig.footer.height,
  },
  main: {
    //maxHeight: `calc(100vh - ${layoutConfig.header.height}px - ${layoutConfig.footer.height}px)`,
  },
}));

const Layout: React.FC<LayoutProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const sbLeftOpen = useSelector((state: RootState) => state.layout.sidebarLeft.open);
  const sbRightOpen = useSelector((state: RootState) => state.layout.sidebarRight.open);

  const handleToggleSidebar = (type: 'left' | 'right') => {
    if (type === 'left') {
      dispatch(st_layout_setSidebarLeftOpen(!sbLeftOpen));
    } else {
      dispatch(st_layout_setSidebarRightOpen(!sbRightOpen));
    }
  };

  return (
    <div className="relative h-screen flex flex-1 flex-col overflow-hidden">
      {!layoutConfig.header.disabled ? (
        <div className={clsx(classes.headerRoot, 'relative flex-none')}>
          <LayoutHeader content={props.header} handleToggleSidebar={handleToggleSidebar} />
        </div>
      ) : null}
      <div className={clsx('relative flex-1 flex-grow', !mobile ? 'flex' : '')}>
        {!mobile ? (
          <LayoutSidebar
            open={sbLeftOpen}
            handleToggleOpen={() => handleToggleSidebar('left')}
            type="left"
            content={props.sidebarLeft}
          />
        ) : (
          <MobileLayoutSidebar
            open={sbLeftOpen}
            handleToggleOpen={() => handleToggleSidebar('left')}
            type="left"
            content={props.sidebarLeft}
            width={layoutConfig.sidebarLeft.mobileWidth}
          />
        )}

        <main className={clsx('flex-grow relative', mobile ? 'h-full' : '')}>{props.main}</main>
        {!layoutConfig.sidebarRight.disabled ? (
          <>
            {!mobile ? (
              <LayoutSidebar
                open={sbRightOpen}
                handleToggleOpen={() => handleToggleSidebar('right')}
                type="right"
                content={props.sidebarRight}
              />
            ) : (
              <MobileLayoutSidebar
                open={sbRightOpen}
                handleToggleOpen={() => handleToggleSidebar('right')}
                type="right"
                content={props.sidebarRight}
                width={layoutConfig.sidebarRight.mobileWidth}
              />
            )}
          </>
        ) : null}
      </div>
      <div className={clsx(classes.footerRoot, 'relative border-t-1 border-gray-300 flex-none')}>
        <LayoutFooter content={props.footer} />
      </div>
    </div>
  );
};

export default Layout;
