/**
 * APP REDUCER
 */

import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { ImageUploadDialogType } from 'store/ckeditor/ckeditorSlice';

import api from 'api';
import { clone } from 'lodash';

interface ImageGalleryState {
  loading: boolean;
  data: any;
  imageUpload: {
    open: boolean;
    dialogType: ImageUploadDialogType;
  };
}

const initialState: ImageGalleryState = {
  loading: false,
  data: null,
  imageUpload: {
    open: false,
    dialogType: 'UPLOAD_DECISION',
  },
};

export const st_imageGallery_init = () => {
  return (dispatch: Dispatch) => {
    dispatch(st_imageGallery_setLoading(true));
    api
      .get('file/filelist?rows=25&start=0&query=type_string_single%3A%2Aheader-images%2A')
      .then((res: any) => {
        const fileList = res.data.items[0];
        if (!fileList.fileIds) {
          fileList.fileIds = [];
        }
        dispatch(st_imageGallery_setLoading(false));
        dispatch(st_imageGallery_setData(fileList));
      });
  };
};

export const st_imageGallery_addNewImage = (fileId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch(st_imageGallery_addImage(fileId));
    dispatch(st_imageGallery_setLoading(true));
    const data = clone(getState().imageGallery.data);
    await api.put('file/filelist', data);
    dispatch(st_imageGallery_setLoading(false));
  };
};

export const st_imageGalelry_removeImagePersist = (fileId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch(st_imageGallery_removeImage(fileId));
    dispatch(st_imageGallery_setLoading(true));
    const data = clone(getState().imageGallery.data);
    await api.put('file/filelist', data);
    dispatch(st_imageGallery_setLoading(false));
  };
};

const imageGallerySlice = createSlice({
  name: 'imageGalelry',
  initialState: initialState,
  reducers: {
    st_imageGallery_setLoading: (state, action) => {
      state.loading = action.payload;
      return state;
    },
    st_imageGallery_setData: (state, action) => {
      state.data = action.payload;
      return state;
    },
    st_imageGallery_addImage: (state, action) => {
      if (state.data.fileIds.indexOf(action.payload) === -1) {
        state.data.fileIds.push(action.payload);
      }
      return state;
    },
    st_imageGallery_removeImage: (state, action) => {
      const imgs = state.data.fileIds.filter((imageId: string) => imageId !== action.payload);
      state.data.fileIds = imgs;
      return state;
    },
    st_imageGallery_initImageUpload: (state, action) => {
      state.imageUpload.open = true;
      state.imageUpload.dialogType = 'UPLOAD_DECISION';
      return state;
    },
    st_imageGallery_setImageUploadDialogType: (state, action) => {
      state.imageUpload.dialogType = action.payload;
      return state;
    },
    st_imageGallery_imageUploadClose: (state, action) => {
      state.imageUpload.open = false;
      return state;
    },
  },
});

export const {
  st_imageGallery_addImage,
  st_imageGallery_removeImage,
  st_imageGallery_setData,
  st_imageGallery_initImageUpload,
  st_imageGallery_setImageUploadDialogType,
  st_imageGallery_imageUploadClose,
  st_imageGallery_setLoading,
} = imageGallerySlice.actions;

export default imageGallerySlice.reducer;
