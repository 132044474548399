/*
   FORM TEXT FIELD
   -> https://material-ui.com/components/text-fields/
*/

import React from 'react';
import { TextField } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

type FormTextFieldProps = {
  name: string;
  f: any;
  onChange?: Function;
  muiProps?: any;
  className?: string;
  style?: CSSProperties;
};

const FormTextField: React.FC<FormTextFieldProps> = (props) => {
  return (
    <TextField
      name={props.name}
      label={props.f.getFieldLabel(props.name)}
      required={props.f.isRequiredField(props.name)}
      onChange={props.onChange || props.f.handleChange}
      onSelect={props.onChange || props.f.handleChange}
      value={props.f.getFieldData(props.name)}
      error={props.f.isFieldError(props.name)}
      helperText={props.f.getFieldMessage(props.name)}
      className={props.className}
      style={props.style}
      {...props.muiProps}
    ></TextField>
  );
};

export default FormTextField;
