import { ListNavigationConfig } from 'components/listNavigation/_types';

const mainNavigationConfig: ListNavigationConfig = {
  id: 'main-navigation',
  //label: 'Main Navigation Label',
  collapsed: true,
  items: [
    {
      id: 'home',
      translate: 'HOME',
      label: 'Startseite',
      icon: ['fas', 'home'],
      path: '/',
    },
    {
      id: 'website',
      // translate: 'CONTENTS',
      label: 'Webseite',
      icon: ['fas', 'globe'],
      path: '/website',

      children: {
        id: 'website-subnav',
        items: [
          {
            id: 'website-home',
            label: 'Home',
            icon: ['fas', 'file'],
            path: '/website/home',
          },
          {
            id: 'website-actual',
            label: 'Aktuell',
            icon: ['fas', 'file'],
            path: '/website/actual',
          },
          {
            id: 'website-sustainability',
            label: 'Nachhaltigkeit',
            icon: ['fas', 'file'],
            path: '/website/sustainability',
          },
          {
            id: 'website-products',
            label: 'Produkte',
            icon: ['fas', 'file'],
            path: '/website/products',
            children: {
              id: 'prducts-nav',
              items: [
                {
                  id: 'products-fuits',
                  label: 'Früchte, Gemüse, Kartoffelprodukte',
                  icon: ['fas', 'file'],
                  path: '/website/products/fuit-legumes',
                },
                {
                  id: 'products-ready-to-cook',
                  label: 'Küchenfertige Produkte',
                  icon: ['fas', 'file'],
                  path: '/website/products/ready-to-cook',
                },
                {
                  id: 'products-frozen',
                  label: 'Tiefkühlprodukte',
                  icon: ['fas', 'file'],
                  path: '/website/products/frozen',
                },
                {
                  id: 'products-milk',
                  label: 'Milchprodukte',
                  icon: ['fas', 'file'],
                  path: '/website/products/milk',
                },
              ],
            },
          },
          {
            id: 'website-about',
            label: 'Über uns',
            icon: ['fas', 'file'],
            path: '/website/about',
            children: {
              id: 'about-nav',
              items: [
                {
                  id: 'about-sgg-waser-ag',
                  label: 'SGG Waser AG',
                  icon: ['fas', 'file'],
                  path: '/website/about/sgg-waser-ag',
                },
                {
                  id: 'about-simmen-gemuese-en-gros-ag',
                  label: 'Simmen Gemüse en gros AG',
                  icon: ['fas', 'file'],
                  path: '/website/about/simmen-gemuese-en-gros-ag',
                },
              ],
            },
          },
          {
            id: 'website-jobs',
            label: 'Jobs',
            icon: ['fas', 'file'],
            path: '/website/jobs',
          },
          {
            id: 'website-contact',
            label: 'Kontakt',
            icon: ['fas', 'file'],
            path: '/website/contact',
            children: {
              id: 'contact-nav',
              items: [
                {
                  id: 'contact-zurich',
                  label: 'Standort Zürich',
                  icon: ['fas', 'file'],
                  path: '/website/contact/zurich',
                },
                {
                  id: 'contact-schlieren',
                  label: 'Standort Schlieren',
                  icon: ['fas', 'file'],
                  path: '/website/contact/schlieren',
                },
                {
                  id: 'contact-simmen-gemuese-en-gros-ag',
                  label: 'Simmen Gemüse en gros AG ',
                  icon: ['fas', 'file'],
                  path: '/website/contact/simmen-gemuese-en-gros-ag',
                },
              ],
            },
          },
          {
            id: 'website-links',
            label: 'Links',
            icon: ['fas', 'file'],
            path: '/website/links',
          },
          {
            id: 'website-shop',
            label: 'Shop',
            icon: ['fas', 'file'],
            path: '/website/shop',
          },
          {
            id: 'website-image-gallery',
            label: 'Bildergallerie',
            icon: ['fas', 'image'],
            path: '/website/image-gallery',
          },
        ],
      },
    },
  ],
};

export default mainNavigationConfig;
