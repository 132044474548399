/**
 * AUTHENTICATION
 */

import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/_types';
import SplashScreen from 'components/splashScreen';
import { st_auth_init } from 'store/auth/authSlice';
import Login from 'components/login';

interface AuthProps {
  embedded?: boolean;
}

const Auth: FC<AuthProps> = (props) => {
  const dispatch = useDispatch();
  const loggedIn = useSelector((state: RootState) => state.auth.loggedIn);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const initAuth = async () => {
      await dispatch(st_auth_init(props.embedded || false));
      setInitialized(true);
    };

    if (!loggedIn) {
      initAuth();
    }
  }, [loggedIn, setInitialized, dispatch, props.embedded]);

  const getContent = () => {
    // already logged in...
    if (loggedIn) {
      return props.children;
    }

    // wait for redirect to keycloak server
    if (!props.embedded) {
      return <SplashScreen />;
    }

    // embedded goto login if token checked
    if (initialized) {
      return <Login />;
    }

    // embedded await token check
    return <SplashScreen />;
  };

  return <>{getContent()}</>;
};

export default Auth;
