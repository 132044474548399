/**
 * HEADER LANGUAGE NAVIGATION
 */

import React, { useState, MouseEvent, useEffect } from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import HeaderNavButton from '../headerNavButton';
import HeaderDropdownNav from '../headerDropdownNav';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/_types';
import ListNavigation from 'components/listNavigation/listNavigation';
import { ListNavigationConfig, ListNavigationItemConfig } from 'components/listNavigation/_types';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { st_language_setLanguage } from 'store/language/languageSlice';
import getListNavigationTheme from '_config/listNavigationThemes';
import { st_languageNavigation_setOpen } from 'store/navigations/languageNavigationSlice';
import { st_userNavigation_setOpen } from 'store/navigations/userNavigationSlice';

const useStyles = makeStyles({
  root: {
    minWidth: 150,
  },
});

interface LanguageNavigationProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
}

const LanguageNavigation: React.FC<LanguageNavigationProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const currentLang = useSelector((state: RootState) => state.language.currentLanguage);
  const langs = useSelector((state: RootState) => state.language.languages);
  const dispatch = useDispatch();
  const classes = useStyles();
  const open = useSelector((state: RootState) => state.navigations.languageNavigation.open);

  useEffect(() => {
    if (!open) {
      setAnchorEl(null);
    }
  }, [open]);

  const handleToggleNav = (event: MouseEvent<any>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
      dispatch(st_languageNavigation_setOpen(true));
      dispatch(st_userNavigation_setOpen(false));
    }
  };

  const handleSelectLanguage = (item: ListNavigationItemConfig) => {
    dispatch(st_language_setLanguage(item.id));
    dispatch(st_languageNavigation_setOpen(false));
  };

  const handleClose = () => {
    dispatch(st_languageNavigation_setOpen(false));
  };

  const navConfig: ListNavigationConfig = {
    id: 'langauge-nav',
    items: [],
  };

  langs.forEach((lang) => {
    navConfig.items.push({
      ...lang,
      active: lang.id === currentLang.id ? true : false,
      icon: ['fas', 'globe'],
      iconActiveOnly: true,
    });
  });

  const navigationContent = (
    <ListNavigation
      theme={getListNavigationTheme('header-nav')}
      classes={clsx(classes.root, 'z-9999')}
      config={navConfig}
      onSelectItem={handleSelectLanguage}
    />
  );

  return (
    <div {...props}>
      <HeaderNavButton
        icon={['fas', 'globe']}
        label={currentLang.id}
        labelPos="right"
        onClick={handleToggleNav}
      />
      <HeaderDropdownNav
        anchorPos="right"
        anchorEl={anchorEl}
        onClose={handleClose}
        content={<div>{navigationContent}</div>}
      />
    </div>
  );
};

export default LanguageNavigation;
