/**
 * MATERIAL UI THEME
 */

import { createMuiTheme, Theme as AugmentedTheme } from '@material-ui/core/styles';
const colors = require('colors');

const appTheme: AugmentedTheme = createMuiTheme({
  typography: {
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
  },
  overrides: {
    MuiOutlinedInput: {
      input: {
        padding: '1.6rem 1.2rem',
      },
    },
  },
});

export default appTheme;
