/**
 * VIEW CONTENT-EDITOR
 */

import React, { FC, useEffect, useState } from 'react';
import { makeStyles, Link, CircularProgress } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/_types';
import Scroll from 'components/scroll';
import clsx from 'clsx';
import CkEditorClient from 'components/ckeditor';
import { Icon } from 'components/icon/icon';
import { st_content_initContent, st_content_saveContent } from 'store/content/contentSlice';
import { clone } from 'lodash';

const useStyles = makeStyles({
  root: {
    maxWidth: 1150,
    minHeight: 300,
  },
});

interface ContentEditorProps {}

const ContentEditor: FC<ContentEditorProps> = (props) => {
  const classes = useStyles();
  const activeItemId = useSelector((state: RootState) => state.navigations.mainNavigation.activeItemId);
  const config = useSelector((state: RootState) => state.content.config);
  const containerWidth = useSelector((state: RootState) => state.content.config?.containerWidth);
  const navConfig = useSelector((state: RootState) => state.content.navConfig);
  const data = useSelector((state: RootState) => state.content.data);
  const [content, setContent] = useState<any>({});
  const dataLength = useSelector((state: RootState) => state.content.data.length);

  const dispatch = useDispatch();

  useEffect(() => {
    if (activeItemId) {
      dispatch(st_content_initContent(activeItemId));
    }
  }, [activeItemId, dispatch]);

  const handleSave = (contentData: any, content: any) => {
    let objtosave: any = clone(content);
    objtosave.text = contentData;
    if (activeItemId) {
      dispatch(st_content_saveContent(objtosave, activeItemId));
    }
  };

  useEffect(() => {
    let content: any = {};
    data.forEach((item) => {
      content[item.id] = { data: item, changed: false };
    });
    setContent(content);
  }, [data, dataLength]);

  const getTitle = (content: any) => {
    let title = content.title;
    if (config && config.titles && (config.titles as any)[content.title]) {
      title = (config.titles as any)[content.title];
    }
    return title;
  };

  const getEditors = () => {
    return (
      <div>
        {data.map((content) => (
          <div className="mt-40">
            <p>{getTitle(content)}</p>
            <CkEditorClient
              id={content.title}
              className="bg-white mt-30 rounded border border-solid border-secondary overflow-hidden"
              data={content}
              contentFieldId="text"
              onSave={(contentData: any) => handleSave(contentData, content)}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <Scroll className="w-full h-full pb-50">
      <div className={clsx(classes.root, 'm-30')}>
        {!navConfig || !config ? (
          <CircularProgress color="secondary" size={50} />
        ) : (
          <>
            <div className="text-32 text-secondary font-bold">{navConfig?.label}</div>
            {config.editable ? (
              <p className="mt-10 text-gray-600">
                <Icon className="mr-10" icon={['fas', 'pen']} />
                Letzte Änderung: <span className="text-gray-800">02.09.2020 18:30</span> von{' '}
                <span className="text-gray-800">thomas.sauter@phoenix-systems.ch</span>
              </p>
            ) : (
              <p className="mt-10 text-gray-600">
                <Icon className="mr-10" icon={['fas', 'pen']} />
                Inhalt nicht editierbar
              </p>
            )}
            <p className="inline-block mt-20">
              <Link
                target="_blank"
                href={`https://sggwaser.ch${config.publicUri}`}
                className="mt-20 text-secondary hover:text-secondary "
              >
                <Icon className="mr-10 inline-block" icon={['fas', 'globe']} />
                Gehe zur Webseite
              </Link>
            </p>
            {config.editable ? (
              <div className="relative" style={{ maxWidth: containerWidth || '100%' }}>
                {data && data.length ? (
                  getEditors()
                ) : (
                  <div className={clsx('bg-white mt-30 rounded border border-solid border-secondar')}>
                    <div className="py-30 flex flex-1 justify-center items-center">
                      <CircularProgress color="secondary" size={40} />
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </>
        )}
      </div>
    </Scroll>
  );
};

export default ContentEditor;
