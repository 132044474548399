/**
 * FOOTER
 */

import React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';

const Footer: React.FC = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div className="flex flex-row justify-between items-center px-15 h-full">
      <p className="text-14 text-white">
        {!mobile
          ? 'SGG WASER AG - Turmstrasse 1 - 8952 Schlieren - Aargauerstrasse1 - 8048 Zürich'
          : 'SGG WASER AG'}
      </p>
      <p className="text-14 text-white hover:underline">
        <a href="mailto:info@sggzh.ch">info@sggzh.ch</a>
      </p>
    </div>
  );
};

export default Footer;
