import { ListNavigationThemeConfig } from '../components/listNavigation/_types';
import { appLogger } from 'components/app/app';
import { clone } from 'lodash';

const listNavigationThemes: ListNavigationThemeConfig[] = [
  {
    id: 'default',
    listItem: {
      borders: true,
      all: 'py-5 px-16',
      default: '',
      active: 'text-white bg-primary hover:text-white hover:bg-primary',
    },
    listIcon: {
      all: 'min-w-30',
      default: '',
      active: 'bg-primary text-white',
    },
    listItemText: {
      all: 'text-inherit overflow-hidden elypsis',
      default: '',
      active: '',
    },
  },
  {
    id: 'primary',
    listItem: {
      borders: false,
      all: 'py-5 px-16 ',
      default: 'text-white bg-primary hover:text-white hover:bg-primary-400',
      active: '',
    },
    listIcon: {
      all: 'min-w-30',
      default: 'bg-transparent text-white',
      active: ' ',
    },
    listItemText: {
      all: 'text-inherit overflow-hidden elypsis',
      default: '',
      active: '',
    },
  },
  {
    id: 'header-nav',
    listItem: {
      borders: false,
      all: 'py-5 px-16 ',
      default: 'text-white bg-secondary hover:text-white hover:bg-secondary-300',
      active: 'text-white bg-secondary hover:text-white hover:bg-secondary-300',
    },
    listIcon: {
      all: 'min-w-30',
      default: 'bg-transparent text-white',
      active: 'bg-transparent text-white',
    },
    listItemText: {
      all: 'text-inherit overflow-hidden elypsis',
      default: '',
      active: '',
    },
  },
  {
    id: 'main-nav',
    listItem: {
      borders: false,
      all: 'py-3 px-16 ',
      default: 'text-white bg-primary hover:text-white hover:bg-primary',
      active: 'text-white bg-secondary hover:text-white hover:bg-secondary',
    },
    listIcon: {
      all: 'min-w-30',
      default: 'bg-transparent text-white',
      active: 'text-white',
    },
    listItemText: {
      all: 'text-inherit overflow-hidden elypsis',
      default: '',
      active: '',
    },
  },
];

export default function getListNavigationTheme(id: string) {
  const theme = listNavigationThemes.filter((theme) => theme.id === id)[0];
  if (!theme) {
    appLogger.log(`no navigation config found with id "${id}"`);
  }
  return clone(theme);
}
