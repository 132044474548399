import { LanguageConfig } from 'i18n/_types';

const languageConfig: LanguageConfig = {
  languages: [
    {
      id: 'DE',
      label: 'Deutsch',
    },
    {
      id: 'EN',
      label: 'English',
    },
    /*  {
      id: 'FR',
      label: 'Français',
    },
    {
      id: 'IT',
      label: 'Italiano',
    }, */
  ],
  defaultLanguageId: 'DE',
};
export default languageConfig;
