/**
 * LIST NAVIGATION
 */

import React from 'react';
import { List, ListSubheader } from '@material-ui/core';
import { ListNavigationConfig, ListNavigationThemeConfig } from 'components/listNavigation/_types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import ListNavigationItem from './listNavigationItem';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface ListNavigationProps {
  config: ListNavigationConfig;
  classes?: string;
  style?: CSSProperties;
  onSelectItem?: Function;
  onToggleChildren?: Function;
  theme: ListNavigationThemeConfig;
  isPopover?: boolean;
}

const ListNavigation: React.FC<ListNavigationProps> = (props) => {
  const { t } = useTranslation('content');

  // TODO check out aria labels and wcag ....

  return (
    <List
      id={props.config.id}
      component="div"
      //  aria-labelledby="nested-list-subheader"
      subheader={
        props.config.label || props.config.translate ? (
          <ListSubheader
            //id="nested-list-subheader"
            component="div"
            classes={{ root: 'whitespace-no-wrap' }}
          >
            {props.config.translate ? t(props.config.translate) : props.config.label}
          </ListSubheader>
        ) : undefined
      }
      classes={{ root: clsx('py-0', props.classes || '') }}
      style={props.style}
    >
      {props.config.items.map((item) => (
        <ListNavigationItem
          key={item.id}
          theme={props.theme}
          config={item}
          collapsed={props.config.collapsed}
          onSelectItem={props.onSelectItem}
          onToggleChildren={props.onToggleChildren}
          isPopover={props.isPopover}
        />
      ))}
    </List>
  );
};

export default ListNavigation;
