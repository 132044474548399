import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ConsoleClient from '@phoenix-systems/console-client';
import { env } from '_config';

// the translations
// (tip move them in a JSON file and import them)

const logger = new ConsoleClient({
  prefix: 'I18N',
  enabled: env === 'development',
});

const resources = {
  en: {
    translation: {
      'Welcome to React': 'Welcome to React and react-i18next',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'DE',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

logger.log('init', [i18n], 'collapsed');

export default i18n;
