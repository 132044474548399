/**
 * VIEW CONTENT-EDITOR
 */

import React from 'react';
import Scroll from 'components/scroll';

interface StartPageProps {}

const StartPage: React.FC<StartPageProps> = (props) => {
  return (
    <Scroll className="w-full h-full">
      <div className="m-30">
        <div className="text-32 text-secondary font-bold">Startseite</div>
      </div>
    </Scroll>
  );
};

export default StartPage;
