/**
 * HEADER USER NAVIGATION
 */

import React, { useState, MouseEvent, useEffect } from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import HeaderNavButton from '../headerNavButton';
import HeaderDropdownNav from '../headerDropdownNav';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/_types';
import { ListNavigationItemConfig, ListNavigationConfig } from 'components/listNavigation/_types';
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import ListNavigation from 'components/listNavigation/listNavigation';
import clsx from 'clsx';
import { Icon } from 'components/icon/icon';
import { st_auth_logout } from 'store/auth/authSlice';
import getListNavigationTheme from '_config/listNavigationThemes';
import { st_languageNavigation_setOpen } from 'store/navigations/languageNavigationSlice';
import { st_userNavigation_setOpen } from 'store/navigations/userNavigationSlice';

interface UserNavigationProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
}

const useStyles = makeStyles({
  root: {
    minWidth: 150,
  },
});

const UserNavigation: React.FC<UserNavigationProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const open = useSelector((state: RootState) => state.navigations.userNavigation.open);

  const classes = useStyles();

  useEffect(() => {
    if (!open) {
      setAnchorEl(null);
    }
  }, [open]);

  const handleToggleNav = (event: MouseEvent<any>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      dispatch(st_languageNavigation_setOpen(false));
      dispatch(st_userNavigation_setOpen(true));
      setAnchorEl(event.currentTarget);
    }
  };

  const handleSelectItem = (item: ListNavigationItemConfig) => {
    if (item.id === 'logout') {
      dispatch(st_auth_logout());
    }
    dispatch(st_userNavigation_setOpen(false));
  };

  const handleClose = () => {
    dispatch(st_userNavigation_setOpen(false));
  };

  const navConfig: ListNavigationConfig = {
    id: 'user-nav',
    items: [
      {
        id: 'logout',
        label: 'Logout',
        icon: ['fas', 'sign-out-alt'],
      },
    ],
  };

  if (!user) {
    return null;
  }

  const navigationContent = (
    <>
      <div className="p-14 text-bold text-14 bg-secondary text-white flex flex-1 flex-row">
        <Icon icon={['fas', 'user-circle']} className="text-20 mr-20" />
        <div>
          <p>{user.name}</p>
          <p className="text-14">{user.email}</p>
        </div>
      </div>
      <ListNavigation
        theme={getListNavigationTheme('header-nav')}
        classes={clsx(classes.root, 'z-9999')}
        config={navConfig}
        onSelectItem={handleSelectItem}
      />
    </>
  );

  return (
    <div {...props}>
      <HeaderNavButton
        icon={['fas', 'user-circle']}
        label={!mobile ? user.name : undefined}
        labelPos={!mobile ? 'right' : undefined}
        onClick={handleToggleNav}
      />
      <HeaderDropdownNav
        anchorPos="right"
        anchorEl={anchorEl}
        content={navigationContent}
        onClose={handleClose}
      />
    </div>
  );
};

export default UserNavigation;
