/**
 * ERROR PAGE CONFIG
 */
import React from 'react';
import { ViewConfig } from 'views/_types';
import ErrorPage from '../errorPage';
import { Redirect } from 'react-router';

const errorPageConfig: ViewConfig = {
  routes: [
    {
      path: '/404',
      exact: true,
      component: ErrorPage,
    },
    {
      path: '/503',
      exact: true,
      component: ErrorPage,
    },
  ],
};

export default errorPageConfig;
