/**
 * LAYOUT CONFIGURATION
 */

import { LayoutConfiguration } from '../_types';

const layoutConfig: LayoutConfiguration = {
  sidebarLeft: {
    collapsed: 56,
    expanded: 320,
    mobileWidth: 320,
  },
  sidebarRight: {
    collapsed: 56,
    expanded: 300,
    mobileWidth: 300,
    disabled: true,
  },
  header: {
    height: 50,
  },
  footer: {
    height: 30,
  },
};

export default layoutConfig;
