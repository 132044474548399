import { combineReducers } from '@reduxjs/toolkit';
import layout from './layout/layoutSlice';
import app from './app/appSlice';
import navigations from './navigations';
import language from './language/languageSlice';
import auth from './auth/authSlice';
import content from './content/contentSlice';
import ckeditor from './ckeditor/ckeditorSlice';
import imageGallery from './imageGallery/imageGallerySlice';

export const rootReducer = combineReducers({
  layout,
  app,
  navigations,
  language,
  auth,
  content,
  ckeditor,
  imageGallery,
});

const createReducer = (asyncReducers?: any) => rootReducer;

export default createReducer;
