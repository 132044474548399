/**
 * MAIN NAVIGATION REDUCER
 */

import { createSlice } from '@reduxjs/toolkit';
import { ListNavigationConfig } from 'components/listNavigation/_types';
import {
  findNavItem,
  getFlatConfig,
  initConfig,
  setOpenSelectedPath,
  findItemByPath,
} from 'components/listNavigation/_utils';
import mainNavigationConfig from 'views/_config/mainNavigation';

interface MainNavigationState {
  config: ListNavigationConfig;
  activeItemId: null | string;
}

const initialConfig = initConfig(mainNavigationConfig, 0, [], null);

const initialState: MainNavigationState = {
  config: initialConfig,
  activeItemId: findItemByPath(mainNavigationConfig)?.id || null,
};

const mainNavigationSlice = createSlice({
  name: 'mainNavigation',
  initialState: initialState,
  reducers: {
    st_mainNavigation_toggleChildren: (state, action) => {
      const item = findNavItem(state.config, action.payload);
      if (item) {
        item.open = !item.open;
      }
    },
    st_mainNavigation_setActive: (state, action) => {
      state.activeItemId = action.payload;
      const flatConf = getFlatConfig(state.config, []);
      flatConf.forEach((item) => {
        item.hasActiveChild = false;
        if (item.id === action.payload) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
      const item = findNavItem(state.config, action.payload);
      if (item && item.idPath) {
        const rootId = item.idPath[0];
        if (rootId) {
          const rootItem = findNavItem(state.config, rootId);
          if (rootItem) {
            rootItem.hasActiveChild = true;
          }
        }
      }
    },
    st_mainNavigation_setCollapsed: (state, action) => {
      state.config.collapsed = action.payload;
    },
    st_mainNavigation_openSelected: (state, action) => {
      if (state.activeItemId) {
        setOpenSelectedPath(state.config, state.activeItemId);
      }
    },
  },
});

export const {
  st_mainNavigation_toggleChildren,
  st_mainNavigation_setActive,
  st_mainNavigation_setCollapsed,
  st_mainNavigation_openSelected,
} = mainNavigationSlice.actions;

export default mainNavigationSlice.reducer;
