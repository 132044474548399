/**
 * START PAGE CONFIG
 */
import { ViewConfig } from 'views/_types';
import StartPage from '../startPage';

const startPageConfig: ViewConfig = {
  routes: [
    {
      path: '/',
      exact: true,
      component: StartPage,
    },
  ],
};

export default startPageConfig;
