/**
 * APP CONFIG
 */

import { AppConfiguration } from '_types';
import kcConfig from './keycloak.json';

const apiEndpoint = 'https://api.crowdlitoken.com';

const appConfig: AppConfiguration = {
  // REST api configuration
  api: {
    enabled: true,
    config: {
      dev: apiEndpoint,
      test: apiEndpoint,
      prod: apiEndpoint,
    },
  },

  // keycloak configuration
  authorization: {
    enabled: true,
    type: 'embeded',
    config: {
      dev: kcConfig,
      test: kcConfig,
      prod: kcConfig,
    },
  },
};

export default appConfig;
