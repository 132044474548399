/**
 * SPLASH SCREEN
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';
import useGlobalStyles from 'css/global';
import clsx from 'clsx';
import logo from '_assets/logo.png';
import LoginForm from './loginForm';

const useStyles = makeStyles({
  logo: {
    maxWidth: 300,
  },
});

interface LoginProps {}

const Login: React.FC<LoginProps> = (props) => {
  const classes = useStyles();
  const globalStyles = useGlobalStyles();

  return (
    <div
      className={clsx(
        'h-screen w-screnn overflow-hidden flex justify-center items-center',
        globalStyles.primaryBgGradient
      )}
    >
      <div className="inline-block text-center text-white ">
        <div className={clsx(classes.logo, 'inline-block mx-auto mb-20')}>
          <img className="w-full h-auto" src={logo} alt="logo" />
        </div>
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
