import { configureStore, Reducer } from '@reduxjs/toolkit';
import createReducer from './rootReducer';

const LOGGER = false;

/* if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer.createReducer());
  });
} */

const store = configureStore({
  reducer: createReducer(),
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === 'development' && LOGGER) {
      const { logger } = require(`redux-logger`);
      return getDefaultMiddleware().concat(logger);
    }
    return getDefaultMiddleware();
  },
  devTools: process.env.NODE_ENV === 'development',
});

(store as any).asyncReducers = {};

export const injectReducer = (key: string, reducer: Reducer) => {
  if ((store as any).asyncReducers[key]) {
    return false;
  }
  (store as any).asyncReducers[key] = reducer;
  store.replaceReducer(createReducer((store as any).asyncReducers));
  return store;
};

//(window as any).store = store;

export default store;
