/**
 * MAIN NAVIGATION REDUCER
 */

import { createSlice } from '@reduxjs/toolkit';

interface LanguageNavigationState {
  open: boolean;
}

const initialState: LanguageNavigationState = {
  open: false,
};

const languageNavigationSlice = createSlice({
  name: 'userNavigation',
  initialState: initialState,
  reducers: {
    st_languageNavigation_setOpen: (state, action) => {
      state.open = action.payload;
    },
  },
});

export const { st_languageNavigation_setOpen } = languageNavigationSlice.actions;

export default languageNavigationSlice.reducer;
