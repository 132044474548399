/**
 * LAYOUT REDUCER
 */

import { createSlice } from '@reduxjs/toolkit';
import { SidebarConfig } from 'components/layout/_types';
import layoutConfig from 'components/layout/_config';

interface LayoutState {
  sidebarLeft: {
    config: SidebarConfig;
    open: boolean;
  };
  sidebarRight: {
    config: SidebarConfig;
    open: boolean;
  };
}

const initialState: LayoutState = {
  sidebarLeft: {
    open: true,
    config: layoutConfig.sidebarLeft,
  },
  sidebarRight: {
    open: false,
    config: layoutConfig.sidebarRight,
  },
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState: initialState,
  reducers: {
    st_layout_setSidebarLeftOpen: (state, action) => {
      state.sidebarLeft.open = action.payload;
    },
    st_layout_setSidebarRightOpen: (state, action) => {
      state.sidebarRight.open = action.payload;
    },
    /*  st_layout_setSidebarLeftWidth: (state, action) => {
      state.sidebarLeft.config = action.payload;
    },
    st_layout_setSidebarRightWidth: (state, action) => {
      state.sidebarLeft.config = action.payload;
    }, */
  },
});

export const {
  st_layout_setSidebarLeftOpen,
  st_layout_setSidebarRightOpen,
  /*  st_layout_setSidebarLeftWidth,
  st_layout_setSidebarRightWidth, */
} = layoutSlice.actions;

export default layoutSlice.reducer;
