/**
 *    CONTENT LINK DIALOG
 */
import React, { useEffect, useState } from 'react';
import { Dialog } from '@material-ui/core';
import { useSelector } from 'react-redux';
import UploadDecision from './uploadDecisiton';
import BrowseLocal from './browseLocal';
import { RootState } from 'store/_types';
import Utils from '_utils';
import { getApiEndpoint } from 'api/api';
import { getTokenFromJsClient } from 'store/auth/authSlice';
import FileUpload from '@phoenix-systems/file-upload';

interface UploadImageDialogProps {}

const UploadImageDialog: React.FC<UploadImageDialogProps> = (props) => {
  const open = useSelector((state: RootState) => state.imageGallery.imageUpload.open);
  const type = useSelector((state: RootState) => state.imageGallery.imageUpload.dialogType);
  const [uploadService, setUploadService] = useState<FileUpload | null>(null);

  useEffect(() => {
    if (open) {
      const upload = new FileUpload({
        id: Utils.uuid(),
        api: {
          endpoint: `${getApiEndpoint()}file`,
          headers: {
            Authorization: `Bearer ${getTokenFromJsClient()}`,
          },
          formDataFieldName: 'file[]',
        },
        //  logger: true,
      });
      setUploadService(upload);
    }
  }, [open]);

  const getDialogSize = () => {
    switch (type) {
      case 'UPLOAD_DECISION':
        return 'sm';
      case 'BROWSE_IMAGE_LOCAL':
        return 'md';
      case 'UPLOAD_IMAGE':
        return 'lg';
      default:
        return 'sm';
    }
  };

  const getDialogContent = () => {
    switch (type) {
      case 'UPLOAD_DECISION':
        return <UploadDecision />;
      case 'BROWSE_IMAGE_LOCAL':
        return <BrowseLocal uploadService={uploadService} />;
      default:
        return null;
    }
  };

  return (
    <Dialog open={open} maxWidth={getDialogSize()} fullWidth>
      <div className="dialog insert-image-dialog m-20">{getDialogContent()}</div>
    </Dialog>
  );
};

export default UploadImageDialog;
