/**
 * PAGE VIEWS CONFIG
 */

import React from 'react';
import contentEditorConfig from '../contentEditor/_config';
import startPageConfig from '../startPage/_config';
import errorPageConfig from '../errorPage/_config';
import { generateRoutesFromConfigs } from '../_utils';
import { Redirect } from 'react-router-dom';
import { RouteConfig } from '../_types';

const routeConfigs = generateRoutesFromConfigs([startPageConfig, contentEditorConfig, errorPageConfig]);

const pageRoutes: RouteConfig[] = [
  ...routeConfigs,
  /* {
    component: () => <Redirect to="/" />,
  }, */
];

export default pageRoutes;
