/**
 *    LOGIN
 */
import React, { useState } from 'react';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import useForm from 'hooks/useForm';
import { useTranslation } from 'react-i18next';
import Utils from '_utils';
import { useDispatch } from 'react-redux';
import FormTextField from '../form/FormTextField';
import clsx from 'clsx';
import { st_auth_login } from 'store/auth/authSlice';

type LoginFormProps = {
  onSuccess?: Function;
  onError?: Function;
};

const LoginForm: React.FC<LoginFormProps> = (props) => {
  const { t } = useTranslation('content');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [valid, setValid] = useState(false);

  const { f } = useForm({
    fields: {
      email: {
        value: '',
        label: 'Email',
        required: true,
        validate: (value: string) => {
          const _valid = Utils.validateEmail(value);
          if (!_valid) {
            return {
              valid: false,
              msg: (t: any) => t('NOT_VALID_EMAIL'),
            };
          } else {
            return {
              valid: true,
              msg: '',
            };
          }
        },
      },
      password: {
        value: '',
        label: (t: any) => t('PASSWORD'),
        required: true,
      },
    },
    validate: true,
    isValid: false,
    requiredText: (t: any) => t('REQUIRED'),
    translate: t,
  });

  const handleLogin = async () => {
    if (f.isValidForm() && !loading) {
      const data = f.getFormData();
      setMessage('');
      setLoading(true);
      const authenticated = await dispatch(st_auth_login(data.email, data.password));
      if (!authenticated) {
        setValid(false);
        setLoading(false);
        setMessage(t('LOGIN_FAILED_MESSAGE'));
      }
    }
  };

  return (
    <div className={clsx('p-20  rounded', !loading ? 'bg-white' : '')}>
      {/*   <h2 className="text-primary text-32 mb-30">
        {loading ? t('LOGGING_IN') : t('LOGIN')}
      </h2> */}
      {message !== '' ? <p className={clsx('message', !valid ? 'error' : '')}>{message}</p> : null}
      <div className="">
        {loading ? (
          <CircularProgress className="text-white" style={{ textAlign: 'center' }} />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormTextField
                name="email"
                f={f}
                muiProps={{
                  variant: 'outlined',
                  //size: 'small',
                  fullWidth: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="password"
                f={f}
                muiProps={{
                  variant: 'outlined',
                  //  size: 'small',
                  fullWidth: true,
                  type: 'password',
                }}
              />
            </Grid>
            <Grid item xs={12} className="text-center">
              <Button
                disabled={!f.isValidForm()}
                variant="contained"
                size="large"
                fullWidth={true}
                color="primary"
                onClick={handleLogin}
              >
                {t('LOGIN')}
              </Button>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default LoginForm;
