/**
 * 	CK EDITOR	-> IMAGE PLUGIN
 */

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';
import store from 'store';
import { st_ckeditor_initImageUpload } from 'store/ckeditor/ckeditorSlice';

class InsertImage extends Plugin {
  init() {
    const editor = this.editor;

    editor.ui.componentFactory.add('insertImage', (locale) => {
      const view = new ButtonView(locale);

      view.set({
        label: 'Insert image',
        icon: imageIcon,
        tooltip: true,
      });

      // Callback executed once the image is clicked.
      view.on('execute', () => {
        store.dispatch(st_ckeditor_initImageUpload(editor.id));
        //store.dispatch(st_content_setEditor(editor));
        /*  const service = new UploadService({});
        console.log(service);

        window.uploadService = service;
        window.editor = editor;
        store.dispatch(st_content_setImageDialogOpen(true)); */
      });

      return view;
    });
  }
}

export default InsertImage;
