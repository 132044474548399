import React, { useRef, useCallback, RefObject, PropsWithChildren, useEffect } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import browserHistory from 'browserHistory';

interface ScrollProps {
  id?: string;
  className?: string;
  style?: CSSProperties;
  ref?: RefObject<HTMLDivElement>;
  onScrollY?: Function;
  onScrollX?: Function;
  onScrollUp?: Function;
  onScrollDown?: Function;
  onScrollLeft?: Function;
  onScrollRight?: Function;
  onYReachStart?: Function;
  onYReachEnd?: Function;
  onXReachStart?: Function;
  onXReachEnd?: Function;
  option?: PerfectScrollbar.Options;
  scrollToTopOnChildChange?: boolean;
  scrollToTopOnRouteChange?: boolean;
  enable?: boolean;
}

const handlerNameByEvent = {
  'ps-scroll-y': 'onScrollY',
  'ps-scroll-x': 'onScrollX',
  'ps-scroll-up': 'onScrollUp',
  'ps-scroll-down': 'onScrollDown',
  'ps-scroll-left': 'onScrollLeft',
  'ps-scroll-right': 'onScrollRight',
  'ps-y-reach-start': 'onYReachStart',
  'ps-y-reach-end': 'onYReachEnd',
  'ps-x-reach-start': 'onXReachStart',
  'ps-x-reach-end': 'onXReachEnd',
};
Object.freeze(handlerNameByEvent);

const Scroll = React.forwardRef<HTMLDivElement, PropsWithChildren<ScrollProps>>((props, ref?) => {
  ref = ref || useRef(null);
  const ps = useRef<any>(null);
  const handlerByEvent = useRef(new Map());

  const hookUpEvents = useCallback(() => {
    Object.keys(handlerNameByEvent).forEach((key) => {
      const callback = (props as any)[(handlerNameByEvent as any)[key]];
      if (callback) {
        const handler = () => callback((ref as any).current);
        handlerByEvent.current.set(key, handler);
        (ref as any).current.addEventListener(key, handler, false);
      }
    });
  }, [ref, props]);

  const unHookUpEvents = useCallback(() => {
    handlerByEvent.current.forEach((value, key) => {
      if ((ref as any).current) {
        (ref as any).current.removeEventListener(key, value, false);
      }
    });
    handlerByEvent.current.clear();
  }, [ref]);

  const destroyPs = useCallback(() => {
    unHookUpEvents();
    if (!ps.current) {
      return;
    }
    (ps.current as any).destroy();
  }, [unHookUpEvents]);

  const createPs = useCallback(() => {
    if (!ref || ps.current) {
      return;
    }
    (ps.current as any) = new PerfectScrollbar((ref as any)?.current, props.option);
    hookUpEvents();
  }, [hookUpEvents, props.option, ref]);

  useEffect(() => {
    function updatePs() {
      if (!ps.current) {
        return;
      }
      (ps as any).current.update();
    }
    updatePs();
  });

  useEffect(() => {
    if (props) {
      createPs();
    } else {
      destroyPs();
    }
  }, [createPs, props, destroyPs]);

  const scrollToTop = useCallback(() => {
    if (ref && (ref as any).current) {
      (ref as any).current.scrollTop = 0;
    }
  }, [ref]);

  useEffect(() => {
    if (props.scrollToTopOnChildChange) {
      scrollToTop();
    }
  }, [scrollToTop, props.children, props.scrollToTopOnChildChange]);

  useEffect(() => {
    browserHistory.listen(() => {
      if (props.scrollToTopOnRouteChange) {
        scrollToTop();
      }
    });
  }, [scrollToTop, props.scrollToTopOnRouteChange]);

  useEffect(
    () => () => {
      destroyPs();
    },
    [destroyPs]
  );

  return (
    <div
      id={props.id}
      className={clsx(
        props && (props.enable || true) ? 'relative overflow-hidden' : '',
        props.className || ''
      )}
      style={props.style}
      ref={ref}
    >
      {props.children}
    </div>
  );
});

export default Scroll;
