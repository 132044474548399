/**
 * 	CK EDITOR	-> PDF PLUGIN
 */

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import fileIcon from './add-file.svg';
import store from 'store';
import { st_ckeditor_initFileUpload } from 'store/ckeditor/ckeditorSlice';

class InsertPdf extends Plugin {
  init() {
    const editor = this.editor;

    editor.ui.componentFactory.add('insertPdf', (locale) => {
      const view = new ButtonView(locale);

      view.set({
        label: 'Insert File',
        icon: `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><defs><style>.cls-1{fill:#141f38;}</style></defs><title>document-add-outline</title><path class="cls-1" d="M115.2,256A12.8,12.8,0,0,1,128,243.2h76.8a12.8,12.8,0,0,1,0,25.6H128A12.8,12.8,0,0,1,115.2,256Zm166.4,38.4H128a12.8,12.8,0,0,0,0,25.6H281.6a12.8,12.8,0,0,0,0-25.6Zm-.38,51.2H128a12.8,12.8,0,0,0,0,25.6H281.22a12.8,12.8,0,0,0,0-25.6Zm218,76.8a89.52,89.52,0,0,1-117.46,85.13A63.4,63.4,0,0,1,358.4,512H76.8a64,64,0,0,1-64-64V64a64,64,0,0,1,64-64H268.94L422.4,153.6V333.82A89.54,89.54,0,0,1,499.2,422.4ZM281.6,49.08V102.4A38.44,38.44,0,0,0,320,140.8h53.09ZM347,486.4A89.48,89.48,0,0,1,396.8,333.82V166.4H320a64,64,0,0,1-64-64V25.6H76.8A38.44,38.44,0,0,0,38.4,64V448a38.44,38.44,0,0,0,38.4,38.4Zm126.65-64a64,64,0,1,0-64,64A64.07,64.07,0,0,0,473.6,422.4Zm-38.4-12.8H422.4V396.8a12.8,12.8,0,1,0-25.6,0v12.8H384a12.8,12.8,0,1,0,0,25.6h12.8V448a12.8,12.8,0,0,0,25.6,0V435.2h12.8a12.8,12.8,0,1,0,0-25.6Z"/></svg>`,
        tooltip: true,
      });

      // Callback executed once the image is clicked.
      view.on('execute', () => {
        store.dispatch(st_ckeditor_initFileUpload(editor.id));
      });

      return view;
    });
  }
}

export default InsertPdf;
