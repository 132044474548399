/**
 *    BROWSE LOCAL
 */
import './insertImageDialog.css';
import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { getApiEndpoint } from 'api/api';
import { st_ckeditor_imageUploadClose } from 'store/ckeditor/ckeditorSlice';
import FileUpload from '@phoenix-systems/file-upload';

interface BrowseLocalProps {
  editor: any;
  uploadService: FileUpload | null;
}

const BrowseLocal: React.FC<BrowseLocalProps> = (props) => {
  const { t } = useTranslation('content');
  const browseContainer = useRef(null);
  const dispatch = useDispatch();
  const [file, setFile] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (browseContainer.current && props.editor && props.uploadService) {
      props.uploadService.initDragDrop(browseContainer.current);
      props.uploadService.uppy.on('file-added', (file: any) => {
        //setLoading(true);
      });

      props.uploadService.onBeforeFileAdded = (file: any) => {
        const absolutePath = URL.createObjectURL(file.data);
        let img = new Image();
        img.src = absolutePath;
        img.onload = () => {
          setFile({
            uploaded: false,
            absolutePath: absolutePath,
            name: file.name,
            mimeType: file.type,
            size: file.data.size,
            originalWidth: img.naturalWidth,
            originalHeight: img.naturalHeight,
          });
        };
        img.onerror = () => {};
      };

      props.uploadService.onUploadProgress = (file: any, progress: any) => {
        console.log(progress);
      };

      props.uploadService.onUploadSucces = (file: any, response: any) => {
        if (response.status === 200) {
          setLoading(false);
          dispatch(st_ckeditor_imageUploadClose(null));
          const imageUrl = `${getApiEndpoint()}file/${response.body[0].fileId}`;
          props.editor.model.change((writer: any) => {
            const imageElement = writer.createElement('image', {
              src: imageUrl,
            });
            // Insert the image in the current selection location.
            props.editor.model.insertContent(imageElement, props.editor.model.document.selection);
          });
        }
      };
    }
  }, [browseContainer, props.uploadService, props.editor, dispatch]);

  const handleUpload = () => {
    if (props.uploadService) {
      setLoading(true);
      props.uploadService.upload();
    }
  };

  return (
    <div className="browse-image">
      <header className="header">
        <h2 className="text-32 font-bold  mb-20">{t('BROWSE_IMAGE')}</h2>
      </header>
      <div className="dialog-content">
        {!file ? (
          <div className="dialog-content-main bg-gray-200">
            <div ref={browseContainer} className="browse-container"></div>
          </div>
        ) : (
          <>
            <div className="image-container relative">
              <img src={file.absolutePath} alt="upload" />
              {loading ? (
                <div className="upload-loader top-0 absolute h-full w-full flex flex-1 justify-center items-center bg-white-25">
                  <div className="inline-block">
                    <CircularProgress color="secondary" size={40} />
                    <p className="text-14 font-bold text-secondary">Loading...</p>
                  </div>
                </div>
              ) : null}
            </div>
          </>
        )}
        <div className="buttons">
          <Button
            variant="contained"
            onClick={() => dispatch(st_ckeditor_imageUploadClose(null))}
            className="ml-20"
          >
            {t('CANCEL')}
          </Button>
          {file ? (
            <Button variant="contained" color="secondary" onClick={handleUpload} className="ml-20">
              {t('UPLOAD_IMAGE')}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default BrowseLocal;
