/**
 * CONTENT REDUCER
 */

import { createSlice, Dispatch } from '@reduxjs/toolkit';
import contentConfig, { ContentConfiguration } from 'views/contentEditor/_config/contents';
import { ListNavigationItemConfig } from 'components/listNavigation/_types';
import { findNavItem } from 'components/listNavigation/_utils';
import api from 'api';
import { orderBy } from 'lodash';
import mainNavigationConfig from 'views/_config/mainNavigation';

export type ImageDialogType = 'UPLOAD_DECISION' | 'BROWSE_IMAGE_LOCAL' | 'UPLOAD_IMAGE';

interface ContentState {
  config: ContentConfiguration | null;
  navConfig: ListNavigationItemConfig | null;
  data: any[];
  imageDialogOpen: boolean;
  uploadService: any;
  editor: any;
  imageDialogType: ImageDialogType;
}

const initialState: ContentState = {
  config: null,
  navConfig: null,
  data: [],
  imageDialogOpen: false,
  uploadService: null,
  editor: null,
  imageDialogType: 'UPLOAD_DECISION',
};

interface ContentObject {
  title: string;
  text: string;
}

const createContent = (contentObj: ContentObject) => {
  return new Promise((resolve, reject) => {
    api.post(`content`, contentObj).then((res: any) => {
      return res.data;
    });
  });
};

export const st_content_initContent = (activeItemId: string) => {
  return (dispatch: Dispatch, getState: any) => {
    dispatch(st_content_setData([]));
    const navConfig = findNavItem(mainNavigationConfig, activeItemId);
    if (navConfig) {
      const config = contentConfig.filter((item: ContentConfiguration) => item.id === navConfig.id)[0];
      dispatch(st_content_setNavConfig(navConfig));
      dispatch(st_content_setConfig(config));

      if (config.editable) {
        api
          .get(`content?query=${encodeURIComponent('title_string_single:' + config.id) + '*'}`)
          .then((res: any) => {
            // create new if not exists
            if (res.data.items.length === 0) {
              createContent({
                title: `${config.id}-1`,
                text: `<p>${navConfig.label}</p>`,
              }).then((res) => {
                api
                  .get(`content?query=${encodeURIComponent('title_string_single:' + config.id) + '*'}`)
                  .then((res: any) => {
                    const data = orderBy(res.data.items, ['title'], ['asc']); // Use Lodash to sort array by 'name'
                    dispatch(st_content_setData(data));
                  });
              });
            }

            // exists already...
            else {
              const data = orderBy(res.data.items, ['title'], ['asc']); // Use Lodash to sort array by 'name'
              dispatch(st_content_setData(data));
            }
          });
      }
    }
  };
};

export const st_content_saveContent = (datatosave: any, activeItemId: string) => {
  return (dispatch: Dispatch, getState: any) => {
    api.put('content', datatosave).then((res: any) => {
      dispatch(st_content_setData([]));
      const navConfig = findNavItem(mainNavigationConfig, activeItemId);
      if (navConfig) {
        const config = contentConfig.filter((item: ContentConfiguration) => item.id === navConfig.id)[0];
        dispatch(st_content_setNavConfig(navConfig));
        dispatch(st_content_setConfig(config));

        if (config.editable) {
          api
            .get(`content?query=${encodeURIComponent('title_string_single:' + config.id) + '*'}`)
            .then((res: any) => {
              // create new if not exists
              if (res.data.items.length === 0) {
                createContent({
                  title: `${config.id}-1`,
                  text: `<p>${navConfig.label}</p>`,
                }).then((res) => {
                  api
                    .get(`content?query=${encodeURIComponent('title_string_single:' + config.id) + '*'}`)
                    .then((res: any) => {
                      const data = orderBy(res.data.items, ['title'], ['asc']); // Use Lodash to sort array by 'name'
                      dispatch(st_content_setData(data));
                    });
                });
              }

              // exists already...
              else {
                const data = orderBy(res.data.items, ['title'], ['asc']); // Use Lodash to sort array by 'name'
                dispatch(st_content_setData(data));
              }
            });
        }
      }
    });
  };
};

export const st_content_loadContent = () => {
  return (dispatch: Dispatch) => {};
};

const contentSlice = createSlice({
  name: 'content',
  initialState: initialState,
  reducers: {
    st_content_setConfig: (state, action) => {
      state.config = action.payload;
      return state;
    },
    st_content_setNavConfig: (state, action) => {
      state.navConfig = action.payload;
      return state;
    },
    st_content_setData: (state, action) => {
      state.data = action.payload;
      return state;
    },
    st_content_setImageDialogOpen: (state, action) => {
      state.imageDialogOpen = action.payload;
      return state;
    },
    st_content_setImageDialogType: (state, action) => {
      state.imageDialogType = action.payload;
      return state;
    },
    st_content_setEditor: (state, action) => {
      state.editor = action.payload;
      return state;
    },
    st_content_setUploadService: (state, action) => {
      state.uploadService = action.payload;
      return state;
    },
  },
});

export const {
  st_content_setConfig,
  st_content_setNavConfig,
  st_content_setData,
  st_content_setImageDialogOpen,
  st_content_setImageDialogType,
  st_content_setEditor,
  st_content_setUploadService,
} = contentSlice.actions;

export default contentSlice.reducer;
