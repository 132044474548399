import React from 'react';
import { findIconDefinition, icon, library, IconPrefix, IconName } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';

export type IconConfig = [IconPrefix, IconName];

export interface IconProps {
  icon: [IconPrefix, IconName];
  ariaLabel?: string;
  id?: string;
  title?: string;
  className?: string;
  styles?: CSSProperties;
  flex?: boolean;
  inline?: boolean;
}

//@ts-ignore
library.add(fas);
//@ts-ignore
library.add(fab);
//@ts-ignore
library.add(far);

export const Icon: React.FC<IconProps> = (props) => {
  const faIcon = findIconDefinition({ prefix: props.icon[0], iconName: props.icon[1] });
  if (!faIcon) {
    console.error(`No icon found with prefix "${props.icon[0]}" and name"${props.icon[1]}"`);
    return null;
  }

  return (
    <i
      id={props.id}
      className={clsx(
        props.flex ? 'flex justify-center items-center content-center' : '',
        props.inline ? 'inline-block' : '',
        props.className || ''
      )}
      aria-hidden="true"
      aria-label={props.ariaLabel}
      title={props.title}
      dangerouslySetInnerHTML={{ __html: icon(faIcon).html[0] }}
    />
  );
};
