/**
 * SPLASH SCREEN
 */

import React from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import useGlobalStyles from 'css/global';
import clsx from 'clsx';
import logo from '_assets/logo.png';

const useStyles = makeStyles({
  logo: {
    maxWidth: 300,
  },
});

interface SplashScreenProps {}

const SplashScreen: React.FC<SplashScreenProps> = (props) => {
  const classes = useStyles();
  const globalStyles = useGlobalStyles();

  return (
    <div
      className={clsx(
        'h-screen w-screnn overflow-hidden flex justify-center items-center',
        globalStyles.primaryBgGradient
      )}
    >
      <div className="inline-block text-center text-white">
        <div className={classes.logo}>
          <img className="w-full h-auto" src={logo} alt="logo" />
        </div>
        <CircularProgress size={40} color="inherit" className="mt-30" />
      </div>
    </div>
  );
};

export default SplashScreen;
