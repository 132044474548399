/**
 *    UPLOAD DECISION
 */
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  st_ckeditor_setImageUploadDialogType,
  st_ckeditor_imageUploadClose,
} from 'store/ckeditor/ckeditorSlice';

const UploadDecision: FunctionComponent = () => {
  const { t } = useTranslation('content');
  const dispatch = useDispatch();

  return (
    <div className="upload-decision">
      <header className="header">
        <h2 className="text-32 font-bold  mb-20">{t('ADD_IMAGE')}</h2>
      </header>
      <div className="dialog-content">
        <Button
          className="mb-15"
          fullWidth
          size="large"
          variant="contained"
          color="secondary"
          onClick={() => dispatch(st_ckeditor_setImageUploadDialogType('BROWSE_IMAGE_LOCAL'))}
        >
          {t('UPLOAD_IMAGE')}
        </Button>
        <Button
          className="mb-15"
          fullWidth
          disabled
          size="large"
          variant="contained"
          color="secondary"
          onClick={() => dispatch(st_ckeditor_setImageUploadDialogType('BROWSE_IMAGE_DB'))}
        >
          {t('SELECT_FROM_DB')}
        </Button>
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="secondary"
          onClick={() => dispatch(st_ckeditor_imageUploadClose({}))}
        >
          {t('CANCEL')}
        </Button>
      </div>
    </div>
  );
};

export default UploadDecision;
