/**
 * LAYOUT SIDEBAR
 */

import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import layoutConfig from './_config';
import { Icon } from 'components/icon/icon';

const useStyles = makeStyles((theme) => ({
  drawerOpen: {
    '&.left': {
      width: layoutConfig.sidebarLeft.expanded,
    },
    '&.right': {
      width: layoutConfig.sidebarRight.expanded,
    },
  },
  drawerClose: {
    '&.left': {
      width: layoutConfig.sidebarLeft.collapsed,
      [theme.breakpoints.down('md')]: {
        width: 0,
      },
    },
    '&.right': {
      width: layoutConfig.sidebarRight.collapsed,
      [theme.breakpoints.down('md')]: {
        width: 0,
      },
    },
  },

  toggleButton: {
    top: 10,
    '&.left': {
      right: -16,
    },
    '&.right': {
      left: -16,
    },
  },
}));

interface LayoutSidebarProps {
  type: 'left' | 'right';
  content: React.ReactNode;
  open: Boolean;
  handleToggleOpen: Function;
}

const LayoutSidebar: React.FC<LayoutSidebarProps> = (props) => {
  const classes = useStyles();
  const [sbHovered, setSbHovered] = useState(false);

  return (
    <Drawer
      anchor={props.type}
      onMouseEnter={() => setSbHovered(true)}
      onMouseLeave={() => setSbHovered(false)}
      variant="permanent"
      className={clsx(
        props.type,
        'transition-width ease-in-out duration-400',
        props.type === 'right' ? 'right-0' : '',
        props.open ? classes.drawerOpen : classes.drawerClose
      )}
      classes={{
        paper: clsx(
          props.type,
          'overflow-y-visible h-full relative transition-width ease-in-out duration-400',
          props.open ? classes.drawerOpen : classes.drawerClose,
          props.type === 'right' ? 'right-0' : ''
        ),
      }}
    >
      <IconButton
        color="secondary"
        onClick={() => props.handleToggleOpen()}
        classes={{
          root: clsx(
            props.type,
            classes.toggleButton,
            'w-30 h-30 p-2 absolute z-9999 bg-secondary text-white absolute rounded-full shadow  hover:bg-secondary hover:text-white transition-transform',
            props.open ? 'transform rotate-180' : 'transform rotate-0',
            sbHovered ? 'opacity-1' : 'opacity-0'
          ),
        }}
      >
        <Icon className="text-16" icon={['fas', props.type === 'left' ? 'chevron-right' : 'chevron-left']} />
      </IconButton>

      <div className="overflow-hidden h-full relative">{props.content}</div>
    </Drawer>
  );
};

export default LayoutSidebar;
