/**
 * SIDEBAR LEFT
 */

import React, { useEffect, useRef, useCallback, useState } from 'react';
import ListNavigation from 'components/listNavigation/listNavigation';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/_types';
import {
  st_mainNavigation_setCollapsed,
  st_mainNavigation_toggleChildren,
} from 'store/navigations/mainNavigationSlice';
import { ListNavigationItemConfig } from 'components/listNavigation/_types';
import getListNavigationTheme from '_config/listNavigationThemes';
import logo from '_assets/logo.png';
import clsx from 'clsx';
import Scroll from 'components/scroll';
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import browserHistory from 'browserHistory';
import { Link } from 'react-router-dom';
import { st_layout_setSidebarLeftOpen } from 'store/layout/layoutSlice';

const useStyles = makeStyles((theme) => ({
  scrollbar: {
    '& .ps__rail-y': {
      background: theme.palette.primary.main + '!important',
    },
    '& .ps__thumb-y': {
      background: 'white !important',
    },
  },
  smallLogo: {
    maxWidth: 150,
  },
}));

const smallLogoHeight = 650;

interface SidebarLeftProps {}

const SidebarLeft: React.FC<SidebarLeftProps> = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const config = useSelector((state: RootState) => state.navigations.mainNavigation.config);
  const open = useSelector((state: RootState) => state.layout.sidebarLeft.open);
  const scrollRef = useRef<HTMLDivElement>(null);
  const sbLeftRef = useRef<HTMLDivElement>(null);
  const [smallLogo, setSmallLogo] = useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    dispatch(st_mainNavigation_setCollapsed(!open));
  }, [open, dispatch]);

  const checkHeight = useCallback(() => {
    if (sbLeftRef.current) {
      const h = sbLeftRef.current.offsetHeight;
      if (h < smallLogoHeight) {
        setSmallLogo(true);
      } else {
        setSmallLogo(false);
      }
      return sbLeftRef.current.offsetHeight;
    }
  }, [sbLeftRef, setSmallLogo]);

  const hoockUpListener = useCallback(() => {
    window.addEventListener('resize', checkHeight);
  }, [checkHeight]);

  const unHoockUpListener = useCallback(() => {
    window.removeEventListener('resize', checkHeight);
  }, [checkHeight]);

  useEffect(() => {
    if (sbLeftRef.current) {
      hoockUpListener();
      checkHeight();
    } else {
      unHoockUpListener();
    }
  }, [sbLeftRef, checkHeight, hoockUpListener, unHoockUpListener]);

  const handleSelectItem = (item: ListNavigationItemConfig) => {
    if (item.path) {
      browserHistory.push(item.path);
      if (mobile) {
        dispatch(st_layout_setSidebarLeftOpen(false));
      }
    }
  };

  const handleToggleChildren = (item: ListNavigationItemConfig) => {
    dispatch(st_mainNavigation_toggleChildren(item.id));
  };

  return (
    <div className="h-full bg-primary flex flex-col flex-1" ref={sbLeftRef}>
      <Link to="/">
        <div
          className={clsx(
            !open ? 'hidden' : '',
            'text-white bg-primary p-16  border-b border-gray-400 whitespace-no-wrap'
          )}
        >
          <img src={logo} alt="logo" className={clsx(smallLogo || mobile ? 'w-136 mx-auto' : '')} />
        </div>
      </Link>
      <Scroll
        ref={scrollRef}
        className={clsx('h-full', classes.scrollbar)}
        id="scroll-sb-left"
        option={{
          suppressScrollX: false,
        }}
      >
        <ListNavigation
          style={{ hegiht: 100000 }}
          theme={getListNavigationTheme('main-nav')}
          config={config}
          onSelectItem={handleSelectItem}
          onToggleChildren={handleToggleChildren}
        />
      </Scroll>
    </div>
  );
};

export default SidebarLeft;
